<template>
  <div class="wrapper-home">
    <transition name="slide">
      <div v-show="visible" class="dialog">
        <transition name="slide">
          <div v-show="dialogSuccess" class="dialog success-tips">发送成功</div>
        </transition>

        <div class="dialog-form">
          <svg @click="close()" class="icon icon-dialog-close">
            <use xlink:href="#lefticon-close"></use>
          </svg>
          <div class="title">
            填写联系信息后，<br />
            文件将发送至邮箱
          </div>
          <div
            style="margin-top: 50px"
            class="le-form-item"
            :class="{ isError: formRule.name }"
          >
            <div class="le-form-title">
              {{ formRule.name ? formRule.name : "姓名" }}
            </div>
            <div class="le-form-main">
              <input
                @focus="formRule.name = false"
                v-model="form.name"
                type="text"
              />
            </div>
          </div>
          <div class="le-form-item" :class="{ isError: formRule.company }">
            <div class="le-form-title">
              {{ formRule.company ? formRule.company : "公司" }}
            </div>
            <div class="le-form-main">
              <input
                @focus="formRule.company = false"
                v-model="form.company"
                type="text"
              />
            </div>
          </div>
          <div class="le-form-item" :class="{ isError: formRule.position }">
            <div class="le-form-title">
              {{ formRule.position ? formRule.position : "职位" }}
            </div>
            <div class="le-form-main">
              <input
                @focus="formRule.position = false"
                v-model="form.position"
                type="text"
              />
            </div>
          </div>
          <div class="flex-center-sb">
            <div
              style="width: calc(50% - 10px)"
              class="le-form-item"
              :class="{ isError: formRule.contactInfo }"
            >
              <div class="le-form-title">
                {{ formRule.contactInfo ? formRule.contactInfo : "手机" }}
              </div>
              <div class="le-form-main">
                <input
                  @focus="formRule.contactInfo = false"
                  v-model="form.contactInfo"
                  maxlength="11"
                  type="text"
                />
              </div>
            </div>
            <div
              style="width: calc(50% - 10px)"
              class="le-form-item"
              :class="{ isError: formRule.code }"
            >
              <div class="le-form-title">
                {{ formRule.code ? formRule.code : "验证码" }}
              </div>
              <div class="le-form-main flex-end-end">
                <input
                  @focus="formRule.code = false"
                  v-model="form.code"
                  type="text"
                />
                <div
                  @click="getAuthCode"
                  style="margin-left: 10px; font-size: 16px"
                  class="le-text-btn-underline"
                  :class="{
                    notclick:
                      count != 0 ||
                      formRule.contactInfo ||
                      formRule.contactInfo == null ||
                      form.contactInfo == '',
                  }"
                >
                  {{ getAuthCodetips }}
                </div>
              </div>
            </div>
          </div>

          <div class="le-form-item" :class="{ isError: formRule.email }">
            <div class="le-form-title">
              {{ formRule.email ? formRule.email : "邮箱" }}
            </div>
            <div class="le-form-main">
              <input
                @focus="formRule.email = false"
                v-model="form.email"
                type="text"
              />
            </div>
          </div>
          <div class="box-btn flex-center-end">
            <div @click="checkForm" style="width: 100px" class="le-button">
              提交
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
  },
  data() {
    return {
      getAuthCodetips: "获取验证码",
      dialogSuccess: false,
      count: 0,
      timer: "",
      form: {
        name: "",
        contactInfo: "",
        email: "",
        company: "",
        position: "",
        code: "",
      },
      formRule: {
        name: null,
        position: null,
        company: null,
        email: null,
        contactInfo: null,
        code: null,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    openSuccessTips() {
      var that = this;
      this.dialogSuccess = true;
      setTimeout(function () {
        that.dialogSuccess = false;
        that.close();
      }, 3000);
    },
    /**
     * 表单的数据校验
     * ------------------------------------------------------------------
     */

    checkForm() {
      var checki = 0;
      if (!this.$checkStr(this.form.contactInfo, "phone")) {
        this.formRule.contactInfo = "* 手机号格式错误";
        checki += 1;
      }
      if (!this.form.contactInfo) {
        this.formRule.contactInfo = "* 填写手机";
        checki += 1;
      }
      if (!this.form.name) {
        this.formRule.name = "* 填写姓名";
        checki += 1;
      }
      if (!this.form.company) {
        this.formRule.company = "* 填写公司";
        checki += 1;
      }
      if (!this.$checkStr(this.form.email, "email")) {
        this.formRule.email = "* 邮箱格式错误";
        checki += 1;
      }
      if (!this.form.position) {
        checki += 1;
        this.formRule.position = "* 填写职位";
      }
      if (!this.form.code) {
        checki += 1;
        this.formRule.code = "* 填写验证码";
      }

      if (checki == 0) {
        this.$buss.POST("/v1/common/contact", this.form).then((res) => {
          if (res.code == 200) {
            this.openSuccessTips();
          }
        });
      }
    },
    /**
     * 获取验证码
     * ------------------------------------------------------------------
     */

    getAuthCode() {
      var that = this;

      if (!that.form.contactInfo) {
        this.formRule.contactInfo = "* 填写手机号";
        return;
      }
      if (
        that.form.contactInfo &&
        !that.$checkStr(that.form.contactInfo, "phone")
      ) {
        this.formRule.contactInfo = "* 手机号格式错误";
        return;
      }

      if (this.count == 0) {
        that.count = 60;

        // 设置定时器 限制60秒发送一次
        that.timer = setInterval(function () {
          that.count -= 1;
          that.getAuthCodetips = "重新发送(" + that.count + "s)";
          if (that.count == 0) {
            that.getAuthCodetips = "获取验证码";
            clearInterval(that.timer);
          }
        }, 1000);
        this.$buss.POST("/v1/common/auth/SendCode", {
          type: 5001,
          phoneNum: this.form.contactInfo,
        });
      }
    },
    close() {
      this.$emit("close");
    },
  },
  created() {},
  beforeUnmount() {
    //清除定时器
    clearInterval(this.coverTimer);
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.dialog-form {
  position: relative;
  width: 528px;
  height: 690px;
  padding: 50px 80px;
  box-sizing: border-box;
  background-color: #fff;
  .title {
    font-size: 32px;
    color: #000; 
    font-weight: bold;
  }
  .icon-dialog-close {
    top: 25px;
    right: 25px;
    color: #000;
    font-size: 22px;
  }
}
.success-tips {
  color: #fff;
  font-weight: bold;
  font-size: 30px;
}
// 手机端
@media screen and (max-width: 579px) {
  .dialog-form {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: scroll;
    padding: 70px 25px;
    box-sizing: border-box;
    background-color: #fff;
    .title {
      font-size: 32px;
      font-weight: bold;
    }
    .icon-dialog-close {
      top: 25px;
      right: 25px;
      color: #000;
      font-size: 22px;
    }
  }
  .success-tips {
    color: #fff;
    font-size: 30px;
  }
}
</style>