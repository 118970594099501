<template>
  <div
    style="
      width: 100%;
      height: auto;
      padding: 20px 20px 20px 20px;
      box-sizing: border-box;
      background-color: #000;
      color: #fff;
    "
    class="wrapper"
  >
    <div style="max-width: 720px; color: #fff; margin: 0 auto" class="main">
      <div style="margin-bottom: 80px" class="cn">
        <div>
          <img
            style="
              height: auto;
              background: #000;
              margin-bottom: 30px;
              color: #fff;
              width: 180px;
            "
            src="https://oss.30sec.com.cn/public/email/topteis.png"
          />
        </div>
        <div>
          <img
            style="
              height: auto;
              background: #000;
              color: #fff;
              margin-bottom: 35px;
              width: 200px;
            "
            src="https://oss.30sec.com.cn/public/email/dttitle.png"
          />
        </div>
        <div
          style="
            font-size: 15px;
            font-weight: 400;
            color: #fff;
            line-height: 28px;
          "
        >
          <div style="font-size: 17px; margin-bottom: 10px; font-weight: 600">
            1、申请加入你们的营销联盟
          </div>
          我们是30秒app，我们是致力于帮助用户了解世界上的独特品牌的平台。我们的做法是，汇聚全球独特品牌的广告，邀请品牌给出独特的优惠，驱动用户看广告后获得优惠券，并跳转到电商购买。在我们的平台，品牌无论小众与大众、强大与渺小、多元与单一、奢侈还是平价，它们的唯一共性是——独特。我们认为，当人们能主动了解这些独特品牌，必然会被它们独特的风格和魅力吸引，了解之后，才有迷恋，我们认为这才是深层、强大、可持续的营销。在我们的平台，一切从品牌广告开始。毫无疑问，独特，也是你们平台的特质，因此，我们已经（或者即将）把你们的平台加入我们App内的个性电商专题中，这有助于追求个性的用户更容易发现你们。请下载我们的App，了解我们。
          <br />
          <br />
          我们已正式上线App
          Store。点击以下链接，下载30秒App（目前仅适用于iOS用户） <br />
          <a
            style="color: #fff; text-decoration: underline"
            href="https://30sec.com.cn/app/download/30sec"
          >
            https://30sec.com.cn/app/download/affiliate</a
          >
          <br />
          <br />
          <br />
          <div style="font-size: 17px; margin-bottom: 10px; font-weight: 600">
            2、邀请你们加入我们的30秒
          </div>
          申请加入你们的营销联盟的同时，我们也邀请你们加入我们的30秒，特别是加入我们平台内的“不可能的优惠”专题。<br />
          我们坚信，独特品牌的成功必须始于用户对它的了解。不是所有用户都具备鉴赏独特品牌及其产品内涵和美学的能力，而独特品牌的广告（影片）是最佳的诠释，故事常常动人心魄。如何让用户主动看广告呢？我们构建了一种模式——“看广告享优惠”，一种简单的交换，用户主动看一次广告（在我们创造出的没有任何干扰的沉浸式观看模式中看），效率远远超过在被动中接触无数次广告。我们相信，专属的有力度的优惠能有力地驱动用户看广告，了解品牌，从而才有机会找到喜欢的品牌，才有机会成为品牌的粉丝和信徒，这才能在品牌和用户之间建立强连接。<br />
          在我们的平台，专设“不可能的优惠”专题，不打折的品牌及其产品在这里给出特定优惠，当用户看广告获得这些特定优惠，他感觉这是付出30秒获得的回报，这丝毫不会伤害品牌，这带来了解和销售！<br />
          <br />
          请联系我们洽谈如何加入30秒。
          <a
            style="
              color: #fff;
              text-decoration-color: #fff;
              text-decoration: underline;
            "
            target="_blank"
            href="Mailto:business@30sec.com.cn"
          >
            <span
              style="
                color: #fff !important;
                text-decoration-color: #fff;
                text-decoration: underline;
              "
              >business@30sec.com.cn
            </span></a
          >
          <br />
        </div>
      </div>
      <div class="en">
        <div
          style="
            font-weight: bolder;
            font-size: 28px !important;
            line-height: 38px;
            display: inline;
            background: #000;
            color: #fff;
          "
        >
          Uniqueness seeking uniqueness
        </div>
        <div
          style="
            font-size: 13px;
            margin-top: 22px;
            color: #fff;
            font-weight: 400;
            line-height: 24px;
          "
        >
          <div style="font-size: 17px; margin-bottom: 10px; font-weight: 600">
            1. Applying to join your affiliate program
          </div>
          We are 30sec App from China, a platform dedicated to helping users
          understand the unique brands of the world. This is how we made it: we
          gather commercial videos from the unique brands around the world,
          invite brands to offer unique discounts, drive users to get coupons
          after watching commercial videos, further to purchase on online
          shopping platforms. In our App, no matter the brands are niche or
          popular, great or small, diverse or monotonous, luxury or affordable,
          their only character in common is——unique. We believe that, when
          people can take the initiative to understand unique brands, they must
          be attracted by unique style and charm of these brands. Obsession will
          come after understanding, and we believe this is deep, powerful and
          sustainable marketing. In our App, everything starts with brand
          advertising. Uniqueness, with no doubt, is also a feature of your
          platform. Therefore, we have already added (or will add) your platform
          to the special section of “Unique E-shop” in our App, which helps
          distinction-seeking users find you in an easier way. Please download
          our App to learn more about us. <br />  <br /> 
           Our App is already on App Store. Click the following link, download 30sec App (currently available for
          iOS users)
          <br />
          <a
            style="color: #fff; text-decoration: underline"
            href="https://30sec.com.cn/app/download/30sec"
          >
            https://30sec.com.cn/app/download/affiliate</a
          >
          <br />
          <br />
          <div style="font-size: 17px; margin-bottom: 10px; font-weight: 600">
            2. Inviting you to join our 30sec App
          </div>

          While applying to join your affiliate program, we invite you to join
          our 30sec, especially the special section of “Impossible
          Discounts”.<br />
          We firmly believe that the success of the unique brand must begin with
          the understanding from users. Not all users have the ability to
          appreciate the unique brand with its product concept and aesthetics,
          by this time the advertisement (commercial video) of unique brand with
          its soul-stirring stories is the best interpretation. How to make user
          watch commercial videos actively? We built a model——“watch and gain”,
          a simple exchange. User take the initiative to watch a commercial
          video (in the immersive viewing mode we created without any
          interference), and it’s far more efficient than accessing countless
          commercial video in a passive way. We believe, the discount which is
          exclusive and powerful can effectively drive users to watch commercial
          videos and understand the brand, so that they will have an opportunity
          to find the brands they like, and to become fans and followers of
          these brands. This is the way to create a strong connection between
          the brand and the user. <br />In our App, there is the special section
          of “Impossible Discounts”, where the brands and products never on sale
          will offer the specific discounts. When the user receives these
          specific discounts by watching commercial videos, he will regard it as
          a reward for paying 30 seconds. It hurts nothing of the brand,
          conversely it brings understanding and sales!<br /><br />
          Please contact us to learn more about how to join 30sec.
          <a
            style="
              color: #fff;
              text-decoration-color: #fff;
              text-decoration: underline;
            "
            target="_blank"
            href="Mailto:business@30sec.com.cn"
          >
            <span
              style="
                color: #fff !important;
                text-decoration-color: #fff;
                text-decoration: underline;
              "
              >business@30sec.com.cn
            </span></a
          >
        </div>
      </div>

      <div
        style="margin-top: 40px; margin-bottom: 50px; text-align: center"
        class="intro-box"
      >
        <div
          style="
            text-align: center;
            width: 100%;
            max-width: 320px;
            padding: 10px 0px;
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <img
            style="
              height: auto;
              display: inline-block;
              margin: 0px auto;
              width: 160px;
            "
            src="https://oss.30sec.com.cn/public/email/p-title-1.png"
          />

          <img
            style="
              width: 100%;
              display: inline-block;
              margin: 0px;
              padding: 0px 10px;
              box-sizing: border-box;
              height: auto;
            "
            src="https://oss.30sec.com.cn/public/email/email-footer-1.png?x-oss-process=style/email_invite"
          />
        </div>
        <div
          style="
            text-align: center;
            width: 100%;
            max-width: 320px;
            padding: 10px 0px;
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <img
            style="height: auto; width: 180px"
            src="https://oss.30sec.com.cn/public/email/p-title-2.png"
          />

          <img
            style="
              width: 100%;
              margin: 0px;
              display: inline-block;
              padding: 0px 10px;
              box-sizing: border-box;
              height: auto;
            "
            src="https://oss.30sec.com.cn/public/email/email-footer-2.png?x-oss-process=style/email_invite"
          />
        </div>
        <div
          style="
            text-align: center;
            width: 100%;
            max-width: 320px;
            padding: 10px 0px;
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <img
            style="height: auto; display: inline-block; width: 200px"
            src="https://oss.30sec.com.cn/public/email/p-title-3.png"
          />

          <img
            style="
              margin: 0px;
              display: inline-block;
              padding: 0px 10px;
              box-sizing: border-box;
              width: 100%;
              height: auto;
            "
            src="https://oss.30sec.com.cn/public/email/email-footer-3.png?x-oss-process=style/email_invite"
          />
        </div>
        <div
          style="
            text-align: center;
            width: 100%;
            max-width: 320px;
            padding: 10px 0px;
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <img
            style="height: auto; display: inline-block; width: 175px"
            src="https://oss.30sec.com.cn/public/email/p-title-4.png"
          />

          <img
            style="
              margin: 0px;
              padding: 0px 10px;
              display: inline-block;
              box-sizing: border-box;
              width: 100%;
              height: auto;
            "
            src="https://oss.30sec.com.cn/public/email/email-footer-4.png?x-oss-process=style/email_invite"
          />
        </div>
      </div>
      <div
        style="height: auto; color: #777; padding-top: 20px"
        class="qrcode-box"
      >
        <img
          style="width: 100px; margin: 0px; padding: 0px; height: auto"
          src="https://oss.30sec.com.cn/public/home/ios-download.png"
        />
        <div style="margin-top: 10px; font-size: 13px">
          扫描二维码，下载30秒App
        </div>
        <div style="font-size: 13px">Scan QR code, download 30sec App</div>
      </div>
      <div style="padding-top: 180px" class="footer">
        <img
          width="70"
          style="
            width: 70px;
            background: #000;
            margin-bottom: 30px;
            height: auto;
          "
          class="logo"
          src="https://oss.30sec.com.cn/public/email/logo.png"
        />
        <div style="font-size: 13px; line-height: 22px">
          前往我们的官网，了解更多<a
            style="color: #fff !important; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/"
            >30sec.com.cn</a
          ><br />
        </div>
        <div style="font-size: 13px; line-height: 22px; margin-bottom: 40px">
          Go to our official website, know more about us
          <a
            style="color: #fff !important; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/home/en"
          >
            <span style="color: #fff !important"> 30sec.com.cn</span></a
          >
          <br />

          Email:
          <a
            style="
              color: #fff;
              text-decoration-color: #fff;
              text-decoration: underline;
            "
            target="_blank"
            href="Mailto: business@30sec.com.cn"
          >
            <span style="color: #fff !important; text-decoration: underline">
              business@30sec.com.cn
            </span></a
          ><br />
          Tel:

          <span style="color: #fff !important; text-decoration: none">
            +8620 8765 0918 </span
          ><br />Guangzhou, Guangdong, China 510220 (P.C.)
        </div>
        <div style="font-size: 12px; color: #777777; line-height: 18px">
          如无法浏览图片内容，请<a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/Affiliate"
            >点击此处</a
          >。<br />
          版权所有 ©LeFt ©2020 30sec。保留所有权利。<br />
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/useragreement"
            >用户协议</a
          >
          ｜
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/privacypolicy"
            >隐私政策</a
          >
        </div>

        <div
          style="
            font-size: 12px;
            line-height: 18px;
            color: #777777;
            margin-bottom: 10px;
          "
        >
          If you are not able to browse the images, please
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/Affiliate"
          >
            click here</a
          >.<br />
          Copyright ©LeFt ©2020 30sec. All rights reserved.<br />
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/useragreement"
            >Users Agreement</a
          >
          ｜
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/privacypolicy"
            >Privacy Policy</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
