<template>
  <div v-html="text"></div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  props: {},
  data() {
    return {
      text: "",
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    var that = this;
    axios
      .get("https://app.api.30sec.com.cn/v1/comm/DictionaryLong?type=102")
      .then(function(response) {
        // handle success
        that.text = response.data.data[0].value;
      });
  },
  mounted() {},
};
</script>
<style>
.wrapper {
  max-width: 1000px;
}
@media screen and (max-width: 569px) {
  .wrapper {
    padding: 30px !important;
  }
}
</style>
