<template>
  <div class="wrapper">
    <!-- 左边 -->
    <div class="coupon-wrapper" :class="{ isBg: !couponData }">
      <div
        v-if="couponData"
        :style="'background:url(' + couponData.bgUrl + ')'"
        class="left"
        :class="{ isShort: couponData.type == 301 }"
      >
        <div v-if="isShowCoupon" class="coupon-box">
          <!-- 金额 -->
          <div v-if="couponData.type != 301" class="money">
            <div class="money-text">
              <span :style="setStyle" v-if="couponData.money">{{
                "¥" + couponData.money
              }}</span>
              <span
                :style="setStyle"
                v-if="couponData.discount"
                class="discount-num"
                >{{ couponData.discount }} <span class="discount-text">折</span>
              </span>
            </div>
            <div class="type-text">
              <span
                style="margin-bottom: 5px"
                :style="setStyle"
                v-if="brandData"
              >
                {{
                  brandData.showFirst == 1
                    ? brandData.chineseName || brandData.englishName
                    : brandData.englishName || brandData.chineseName
                }}
              </span>
              <span :style="setStyle" v-if="brandData">{{
                getTypeText(couponData.type)
              }}</span>
            </div>
          </div>
          <!-- 上线中 -->
          <div v-if="couponData.type == 301" class="money">
            <div class="coming-soon">
              <span :style="setStyle" class="xhx-box"> ¥ </span>
              <span :style="setStyle" class="text">优惠上线中</span>
            </div>
          </div>

          <div v-if="couponData.type != 301" class="content">
            <!-- 上面信息 -->
            <!-- 满减信息 -->

            <span>
              <span :style="setStyle">*</span>
              <span :style="setStyle" v-if="couponData.minUsePrice"
                >满 {{ couponData.minUsePrice }} 使用
              </span>
              <span
                :style="setStyle"
                v-if="couponData.shopName && couponData.minUsePrice"
                class="icon-gan"
                >/</span
              >

              <!-- 店铺名称 -->
              <span :style="setStyle" v-if="couponData.shopName"
                >{{ couponData.shopName }}
              </span>
              <!-- 优惠截止日期 -->
              <span :style="setStyle" v-if="couponData.end" class="icon-gan"
                >/</span
              >

              <span :style="setStyle" v-if="couponData.end">
                有效期至 {{ couponData.end.slice(0, 10) }}
              </span> </span
            ><br />
            <p
              v-if="couponData.info && couponData.info.length < 30"
              style="height: 8px"
            ></p>
            <!-- 下面信息 -->
            <!-- 优惠信息 -->
            <span v-if="couponData.info" :style="setStyle"
              >* {{ couponData.info }}</span
            >
          </div>
        </div>
      </div>
      <!-- 内容信息 -->

      <!-- 右边 品牌logo -->
      <div v-if="brandData && couponData" class="brand-logo">
        <img :src="brandData.logoUrl" />
      </div>
    </div>
  </div>
</template>


<script>
/**
 * 组件说明
 *
 * 这是一个优惠券组件
 *
 * 关于props
 * adData:这是广告实体的数据
 * brandData:这是品牌实体的数据
 * couponData:这是优惠券实体数据
 *
 * @date     2021 /04 /25
 * @author   Kamtao
 *
 */

export default {
  components: {},
  props: {
    adData: {},
    brandData: {},
    couponData: {},
  },
  data() {
    return {};
  },
  computed: {
    /**
     * 判断优惠券信息是否完整
     * ------------------------------------------------------------------
     */

    isShowCoupon() {
      var _couponData = this.couponData;
      if (_couponData.type == 301) {
        return true;
      } else {
        if (
          (_couponData.money || _couponData.discount) &&
          _couponData.type &&
          _couponData.shopName
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    /**
     * 设置优惠券样式
     * ------------------------------------------------------------------
     */

    setStyle() {
      var color = "color:" + this.couponData.fontColor + ";";
      var fontBgColor = "background-color:" + this.couponData.fontBgColor + ";";
      return color + fontBgColor;
    },

    /**
     * 根据优惠券type获取优惠券类型文本
     * ------------------------------------------------------------------
     */

    getTypeText() {
      return function (type) {
        var typeText = "";
        switch (type) {
          case 501:
            typeText = "指定券";
            break;
          case 601:
            typeText = "通用券";
            break;
          case 301:
            typeText = "上线中";
            break;
          default:
            break;
        }
        return typeText;
      };
    },
  },
  watch: {},
  mounted: function () {},
  created: function () {},
  methods: {},
};
</script>

<style scoped>
.wrapper {
  position: relative;
  z-index: 999;
  overflow: unset !important;
}

.coupon-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 90px;
}
.isBg {
  background-color: #a5a5a5;
}

.left {
  height: 90px;
  width: 1000px;
  position: relative;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-size: cover !important;
  color: #fff;
}
.isShort {
  width: 1000px;
  overflow: hidden;
}
.coupon-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.endDate-info-wrapper {
  position: relative;
  width: 240px;
  height: 90px;
  left: -15px;
  overflow: hidden;
  top: 0px;
}

.endDate-info {
  position: absolute;
  left: -40px;
  top: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  padding: 5px 35px;
  background-color: #000;
  transform: rotate(-35deg);
}

.left .money {
  width: 380px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.money-text span {
  display: flex;
  font-size: 64px;
  font-weight: 900;
  line-height: 70%;
  padding-bottom: 8px;
  margin-right: 10px;
}
.type-text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 23px;
  font-weight: 700;
  line-height: 111%;
}

.coming-soon {
  margin-left: 10px;
  font-size: 60px;
  line-height: 55px;
  margin-right: 20px;
  font-weight: 700;
  display: inline;
  color: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.coming-soon .xhx-box {
  line-height: 55px;
  margin-top: -3px;
  display: flex;
  align-items: center;
}

.coming-soon .text {
  font-size: 40px;
  line-height: 44px;
  margin-top: -6px;
  font-weight: 800;
  margin-left: 20px;
}

.discount-num {
  font-size: 60px !important;
  display: flex;
  font-weight: 900;
  line-height: 70%;
  padding-bottom: 8px;
  margin-right: 10px;
}

.discount-text {
  transform: translateY(4px);
  font-size: 55px !important;
}

.content {
  width: 550px;
  height: 90px;
  padding: 15px;
  box-sizing: border-box;
}

.content span {
  font-size: 16px;
  box-sizing: border-box;
  line-height: 26px;
  color: #fff;
  z-index: 9999;
  position: relative;
}

.brand-logo {
  width: 118px;
  height: 90px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px #eee solid;
  box-sizing: border-box;
  justify-content: center;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAAA+CAYAAACWX20oAAAABHNCSVQICAgIfAhkiAAAAPFJREFUeF7t2TEOwjAQRNHk/oc2ogJLMeNp8aNdN/n6u/Ys9xV+Y4yRzvxj/U4fBcyC0LFgTv3w1Ck3MM+IgFmoAwwwaarMdcYwpjQmHT/11vLyXbUSYxbvGGCASQ5MdTPGjKmEuRjDGMZ0BESCjpcQyRjGdAQY0/EyY7xjSmPScYsq/0RK16lL3nUh0vDd8eRzhjGMYUxHgDEdL5FAiGRMR4AxHS8zhjGlMem4tYO1g7VD6hJrhx+EpGtZaaeB7GMiJa2klaIkrusdRLKSrLTjydetdGoWSpi0klZKjsx1xnjHlMak46cOZ5FAK6XemOsvJaz3R08+2qcAAAAASUVORK5CYII=)
    no-repeat;
  background-color: #eee;
  background-size: cover !important;
}

.brand-logo img {
  border-radius: 50%;
  width: 80px;
  margin-left: 18px;
  margin-right: 5px;
  height: 80px;
}
</style>
