<template>
  <div class="wrapper">
    <div class="right">
      © 2020 LeFt
      <a href="https://beian.miit.gov.cn" target="_blank"
        >粤ICP备2020076228号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.right {
  margin-top: 20px;
  width: 100%;
  display: flex;
  color: #fff;
  justify-content: center;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 1px;
  opacity: 0.6;
  align-items: center;
  white-space: nowrap;
  a {
    color: #fff;
  }
}
</style>