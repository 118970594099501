<template>
  <div
    style="
      width: 100%;
   letter-spacing: -0.5px;
      height: auto;
      background-color: #000;
      color: #fff;
    "
  >
    <div
      style="
        background-color: #000;
        max-width: 490px;
        padding: 15px;
        margin: 0 auto;
      "
      class="wrapper"
    >
      <table align="center" style="width: 100%;">
        <tbody>
          <tr>
            <td style="font-size: 16px; color: #fff; padding: 40px 0px">
              Hi<span v-if="chineseName">，{{ chineseName }}</span>
            </td>
          </tr>
          <tr>
            <td
              style="
                font-size: 13px;
                font-weight: 400;
                color: #fff;   
                line-height: 24px;
              "
            >
              欢迎你使用30秒！ <br />
              “30秒是故事之地，是激励广告平台”； <br />
              在碎片化的、喧嚣嘈杂的移动互联网，30秒是纯粹的投放品牌广告的媒介；
              <br />
              30秒的模式是以激励驱动用户主动看广告—— <br />
              看完广告、拿到优惠、跳转电商。30秒把故事（做品牌）和效果（购买）结合在一起；
              <br />
              在30秒，广告是内容，而非插在内容中的干扰。30秒的模式让广告不再令人反感，让广告不再被跳过、被静音、被VIP去广告、被广告拦截软件拦截，也不会被机器人看、被数据造假。30秒确保广告百分百到达。
              <br />
              <br />
            </td>
          </tr>
          <tr>
            <td style="font-size: 13px; line-height: 26px">如何入驻30秒？</td>
          </tr>
          <tr align="left">
            <td
              style="
                font-size: 13px;
                font-weight: 400;
                color: #fff;
                line-height: 24px;
              "
            >
              <span style="color: #fff">1、了解：</span>
              <a
                style="
                  color: #fff;
                  text-decoration: none;
                  border-bottom: 1px #fff solid;
                  white-space: nowrap;
                "
                target="_blank"
                href="https://www.30sec.com.cn/app/download"
              >
                <span style="color: #fff">下载30秒app </span></a
              >
              、
              <a
                style="
                  color: #fff;
                  white-space: nowrap;
                  text-decoration: none;
                  border-bottom: 1px #fff solid;
                "
                target="_blank"
                href="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/business/30秒是什么？.pdf"
              >
                <span style="color: #fff">下载《30秒是什么？》 </span></a
              >
            </td>
          </tr>
          <tr>
            <td style="font-size: 13px; color: #fff; line-height: 26px">
              2、登录：
              <a
                style="
                  color: #fff;
                  text-decoration: none;
                  border-bottom: 1px #fff solid;
                "
                target="_blank"
                href="https://www.30sec.com.cn"
              >
                <span style="color: #fff">30秒官网 </span></a
              >，前往企业入口注册认证
            </td>
          </tr>
          <tr>
            <td style="font-size: 13px; color: #fff; line-height: 26px">
              3、上传：品牌广告片、设定（独家、可叠加）优惠券
            </td>
          </tr>
        </tbody>
      </table>

      <div
        style="margin-top: 48px; margin-bottom: 57px; text-align: left"
        class="intro-box"
      >
        <div
          style="
            text-align: center;
            width: 49%;
            max-width: 180px;
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <img
            style="
              margin: 0px;
              display: inline-block;
              padding: 0px 10px;
              box-sizing: border-box;
              width: 100%;
              height: auto;
            "
            src="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/email/p1.png"
          />
        </div>
        <div
          style="
            text-align: center;
            width: 49%;
            max-width: 180px;
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <img
            style="
              margin: 0px;
              padding: 0px 10px;
              display: inline-block;
              box-sizing: border-box;
              width: 100%;
              height: auto;
            "
            src="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/email/p2.png"
          />
        </div>
      </div>
      <table align="center" style="width: 100%">
        <tbody>
          <tr>
            <td
              style="
                font-size: 13px;
                font-weight: 400;
                color: #fff;
                line-height: 24px;
              "
            >
              在30秒app搜索你的品牌，有可能你会发现你们的品牌已经在列，我们的团队主动搜索到你们的广告和你们在电商平台上的现存优惠，希望给你一个真实的体验。当你们正式入驻30秒平台，即可上传符合你们营销计划的广告片和设定优惠券（符合平台制定的优惠政策），平台亦同时撤下现存广告及优惠。如果你们决定不入驻，也可保留现存广告及优惠，或通知我们撤下。
              <br />
              <br />
              诚挚邀请你们入驻30秒， <br />让我们一起开启全新的品牌旅程！ <br />
              <br />
              30秒团队
            </td>
          </tr>
        </tbody>
      </table>
      <table align="center" style="width: 100%; margin-top: 50px">
        <tbody>
          <tr>
            <td
              style="
                font-size: 13px;
                font-weight: 400;   letter-spacing:0px;
                color: #fff;
                line-height: 24px;
              "
            >
              Hi<span v-if="englishName">, {{ englishName }}</span> <br /><br />
              Welcome to 30sec!

              <br />
              “30sec is the land of story, and the platform of incentive
              advertisement.”;<br />
              On the mobile Internet which is fragmented and noisy, 30sec is a
              media purely for brand advertising;<br />
              The model of 30sec drives users to actively watch commercial
              videos by incentives—— <br />Watch a video, get a discount, and
              jump to online shops. 30sec combines the story (building brand)
              and effectiveness (purchasing);<br />
              At 30sec, advertisement is the content, instead of the
              interruption to the content. The model of 30sec makes the
              advertisement no more annoying, the advertisements will no more be
              skipped, be muted, be removed by VIP, be intercepted by
              ads-blocking software, be viewed by robots, be falsified by fake
              data. 30sec ensures the 100% arrivals of advertisements.
              <br /><br />
              How to join 30sec?<br />
              1.Know more about us:
              <a
                style="
                  color: #fff;
                  text-decoration: none;
                  border-bottom: 1px #fff solid;
                "
                target="_blank"
                href="https://www.30sec.com.cn/app/download"
              >
                <span style="color: #fff">download 30sec app </span></a
              >,
              <a
                style="
                  color: #fff;
                  text-decoration: none;
                  border-bottom: 1px #fff solid;
                "
                target="_blank"
                href="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/business/30秒是什么？.pdf"
              >
                <span style="color: #fff"
                  >download the file “What is 30sec”
                </span></a
              >
              document.<br />
              2.Log-in: go to
              <a
                style="
                  color: #fff;
                  text-decoration: none;
                  border-bottom: 1px #fff solid; 
                "
                target="_blank"
                href="https://www.30sec.com.cn"
              >
                <span style="color: #fff">our official website </span></a
              >, sign-in from the portal of “Business Users” (企业入口）<br />
              3.Upload: upload brand videos, set coupons which are exclusive or
              applicable with other discounts.
              <br /><br />
              You may find your brand is already on 30sec app. Our team has
              searched and collected your commercial videos and existing
              discounts on online shops, in order to offer you an authentic
              experience. When you officially join 30sec, you can upload the
              commercial videos fitting with your marketing campaigns, and set
              the discount which is in compliance with the policy of our
              platform. At the same time, we will remove your existing
              commercial videos and discounts on the app. Also if you decide not
              to join, you can choose to keep current videos and discounts on
              our app or inform us to remove them.
              <br /><br />
              We sincerely invite you to join 30sec.<br />
              Let’s start a brand-new brand journey together!<br /><br />
              30sec Team
            </td>
          </tr>
        </tbody>
      </table>
      <table style="margin-top: 100px">
        <tbody>
          <tr>
            <td align="center" style="padding-right: 15px">
              <img
                style="width: 90px; margin: 0px; padding: 0px; height: auto"
                src="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/email/ios-download.png"
              />
              <div
                style="
                  margin-top: 5px;
                  color: #959595;
                  white-space: nowrap;
                  font-weight: 400;   line-height: 24px;
                  font-size: 12px;
                "
              >
                30秒iOS客户端<br />iOS app
              </div>
            </td>
            <td align="center" style="padding-right: 15px">
              <img
                style="width: 90px; margin: 0px; padding: 0px; height: auto"
                src="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/email/miniApp-download.png"
              />
              <div
                style="
                  margin-top: 5px;
                  color: #959595;
                  font-weight: 400; 
                  white-space: nowrap; line-height: 24px;
                  font-size: 12px;
                "
              >
                30秒微信小程序 <br />WeChat mini program
              </div>
            </td>
            <td align="center">
              <img
                style="width: 90px; margin: 0px; padding: 0px; height: auto"
                src="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/email/business-wechat.png"
              />
              <div
                style="
                  margin-top: 5px;
                  color: #959595;
                  font-weight: 400;
                  white-space: nowrap; line-height: 24px;
                  font-size: 12px;
                "
              >
                微信联系 <br />WeChat contact
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table style="margin-top: 200px">
        <tbody>
          <tr>
            <td>
              <img
                width="70"
                style="
                  width: 70px;
                  background: #000;
                  margin-bottom: 30px;
                  height: auto;
                "
                class="logo"
                src="https://oss.30sec.com.cn/public/email/logo.png"
              />
            </td>
          </tr>

          <tr>
            <td>
              <div style="font-size: 12px; color: #777777">
                前往我们的官网，了解更多<a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn"
                  >30sec.com.cn</a
                ><br />
              </div>
              <div style="font-size: 12px; color: #777777; margin-bottom: 40px">
                Go to our official website, know more about us
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn"
                >
                  <span style="color: #777777"> 30sec.com.cn</span></a
                >
                <br />

                Email:
                <a
                  style="
                    color: #777777;
                    text-decoration-color: #777777;
                    text-decoration: underline;
                  "
                  target="_blank"
                  href="Mailto:business@30sec.com.cn"
                >
                  <span
                    style="
                      color: #777777;
                      text-decoration-color: #777777;
                      text-decoration: underline;
                    "
                  >
                    business@30sec.com.cn
                  </span></a
                ><br />Guangzhou, Guangdong, China 510220(P.C.)
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div style="font-size: 12px; color: #777777">
                如无法浏览图片内容，请<a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/invitation"
                  >点击此处</a
                >。<br />
                版权所有 ©LeFt ©2020 30sec。保留所有权利。<br />
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/useragreement"
                  >用户协议</a
                >
                ｜
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/privacypolicy"
                  >隐私政策</a
                >
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div style="font-size: 12px; color: #777777; margin-bottom: 10px">
                If you are not able to browse the images, please
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/invitation"
                >
                  click here</a
                >.<br />
                Copyright ©LeFt ©2020 30sec. All rights reserved.<br />
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/useragreement"
                  >Users Agreement</a
                >
                ｜
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/privacypolicy"
                  >Privacy Policy</a
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {},
  data() {
    return {
      chineseName: "",
      englishName: ""
    };
  },
  watch: {},
  methods: {},
  created() {
    this.chineseName = this.$route.params.chineseName;
    this.englishName = this.$route.params.englishName;
  },
  mounted() {},
  beforeUnmount() {}
};
</script>