<template>
  <div class="header">
    <img class="logo" src="../assets/img/logo.svg" />
    <div class="menu">
      <div v-for="(item, index) in pathList" :key="'path' + index">
        <a
          class="toLink-btn"
          :class="{ active: nowPath == item.path }"
          :href="item.path"
          :target="item.target"
        >
          {{ item.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      pathList: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "了解30秒",
          path: "/About",
        },
        {
          name: "企业入口",
          path: "https://business.30sec.com.cn",
          target: "_blank",
        },
        {
          name: "联系我们",
          path: "mailto:business@30sec.com.cn",
          target: "_blank",
        },
      ],
      nowPath: "",
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    this.nowPath = this.$route.path;
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.header {
  position: absolute;
  z-index: 999;
  display: flex;
  top: 20px;
  left: 30px;
  .logo {
    width: 40px;
    height: auto;
    margin-right: 30px;
  }
  .menu {
    width: 520px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .toLink-btn {
    position: relative;
    display: block;
    margin: 5px;
    opacity: 0.6;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    margin-right: 20px;
    transition: opacity 0.25s;
  }
  .toLink-btn:hover {
    opacity: 1;
  }
  .toLink-btn:hover::after {
    position: absolute;
    left: 0px;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: #fff;
    content: "";
  }
  .active {
    font-weight: bold;
    opacity: 1;
  }
  .active::after {
    position: absolute;
    left: 0px;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: #fff;
    content: "";
  }
}

// 手机端
@media screen and (max-width: 579px) {
  .header {
    flex-direction: column;

    .logo {
      width: 30px;
    }
    .menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 520px;
      margin-top: 10px;
    }
    .toLink-btn {
      margin: 5px 0px;
      font-size: 16px;
    }
  }
}
</style>