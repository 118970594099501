<template>
  <div :style="setWH" class="videoWrapper">
    <transition name="fade">
      <div :style="setWH" class="coverBox" v-if="coverSwitch">
        <img ref="cover" :src="coverUrl" v-if="coverUrl" alt="" />
        <svg
          @click="clickPlay"
          v-show="['pause', 'ended', 'waiting'].indexOf(video.status) >= 0"
          class="icon icon-cover"
          aria-hidden="true"
        >
          <use xlink:href="#lefticon-play"></use>
        </svg>
      </div>
    </transition>
    <video
      playsinline
      webkit-playsinline
      ref="video"
      :src="videoUrl"
      type="video/mp4"
    ></video>
    <div
      @click="showControl"
      @mouseover="showControlSwitch"
      @mouseout="closeControlSwitch"
      :style="controlShowStyle"
      class="videoControlBox"
    >
      <div :style="setWH" class="videoControlWrapper">
        <div :style="controlBottomShowStyle" class="bottom">
          <div class="startTime">{{ video.startTime }}"</div>
          <div
            @click.stop="clickVideo($event)"
            ref="progressBox"
            class="progress-box"
          >
            <div class="progress-line">
              <div
                :style="'width:' + video.bufferLine + '%;'"
                class="buffer-line"
              ></div>
              <div
                :style="'width:' + video.playingLine + '%;'"
                class="playing-line"
              ></div>
            </div>
          </div>

          <div class="endTime">{{ video.endTime }}"</div>
        </div>
        <div @click.stop="clickPlay">
          <svg
            v-show="video.status == 'playing'"
            class="icon icon-play"
            aria-hidden="true"
          >
            <use xlink:href="#lefticon-pause"></use>
          </svg>
          <svg
            v-show="['pause', 'ended', 'waiting'].indexOf(video.status) >= 0"
            class="icon icon-pause"
            aria-hidden="true"
          >
            <use xlink:href="#lefticon-play"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    videoUrl: {
      type: String,
      default: "",
    },
    coverUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      setWH: "",

      coverSwitch: true,
      controlShowStyle: "",
      controlBottomShowStyle: "",
      videoControlStatus: true,
      showTimer: "",
      video: {
        status: "pause",
        startTime: 0,
        endTime: 0,
        bufferLine: 0,
        playingLine: 0,
      },
    };
  },
  watch: {
    videoControlStatus: function (val) {
      if (val) {
        this.controlShowStyle = "animation: videoControlOpen 0.5s;";
        this.controlBottomShowStyle = "animation: videoControlBottomOpen 0.5s;";
      } else {
        this.controlShowStyle = "animation: videoControlClose 0.5s;";
        this.controlBottomShowStyle =
          "animation: videoControlBottomClose 0.5s;";
      }
    },
  },
  computed: {},
  methods: {
    showControlSwitch() {
      this.videoControlStatus = true;
    },
    closeControlSwitch() {
      this.videoControlStatus = false;
    },
    showControl() {
      var that = this;
      clearTimeout(this.showTimer);
      if (this.videoControlStatus) {
        this.videoControlStatus = false;
      } else {
        this.videoControlStatus = true;
        if (this.video.status == "playing") {
          this.showTimer = setTimeout(() => {
            that.videoControlStatus = false;
            clearTimeout(that.showTimer);
          }, 2500);
        }
      }
    },
    clickVideo(e) {
      var that = this;
      var video = this.$refs.video;
      var progressTime =
        (e.offsetX / this.$refs.progressBox.offsetWidth) * video.duration;

      clearTimeout(that.showTimer);
      this.videoControlStatus = true;
      this.showTimer = setTimeout(() => {
        that.videoControlStatus = false;
        clearTimeout(that.showTimer);
      }, 2500);
      video.currentTime = progressTime;
    },
    clickPlay() {
      var that = this;
      var video = this.$refs.video;
      if (this.video.status == "playing") {
        video.pause();
      } else {
        this.coverSwitch = false;
        video.play();
        this.showTimer = setTimeout(() => {
          that.videoControlStatus = false;
          clearTimeout(that.showTimer);
        }, 2500);
      }
    },
    setWhp() {
      var cover = this.$refs.cover;
      this.setWH =
        "width:" + cover.clientWidth + "px;height:" + cover.clientHeight + "px";
    },
    pause() {
      var video = this.$refs.video;
      video.pause();
    },
  },
  created() {
    this.$nextTick(() => {
      this.setWhp();
    });
  },
  mounted() {
    var that = this;
    var video = this.$refs.video;
    video.addEventListener("loadedmetadata", function () {
      that.video.endTime = Math.floor(video.duration);

      that.$emit("leftVideoWidth", video.clientWidth);
    });
    video.addEventListener("waiting", function () {
      //加载
      console.log("加载中");
    });
    video.addEventListener("play", function () {
      //播放开始执行的函数
      that.video.status = "playing";
    });
    video.addEventListener("pause", function () {
      //暂停开始执行的函数
      that.controlShowStyle = "animation: videoControlOpen 0.5s;";
      that.controlBottomShowStyle = "animation: videoControlBottomOpen 0.5s;";
      that.videoControlStatus = true;
      clearInterval(that.showTimer);
      that.video.status = "pause";
    });
    video.addEventListener(
      "ended",
      function () {
        //结束
        that.controlShowStyle = "animation: videoControlOpen 0.5s;";
        that.controlBottomShowStyle = "animation: videoControlBottomOpen 0.5s;";
        that.video.status = "ended";
        that.headerSwitch = false;
        that.downloadSwitch = true;
      },
      false
    );

    //使用事件监听方式捕捉事件

    video.addEventListener(
      "timeupdate",
      function () {
        try {
          //用秒数来显示当前播放进度
          that.video.playingLine = (video.currentTime / video.duration) * 100;
          that.video.startTime = Math.floor(video.currentTime);
          that.video.bufferLine =
            (video.buffered.end(video.buffered.length - 1) / video.duration) *
            100;
        } catch {
          console.log("视频加载中");
        }
        //当视频播放到 4s的时候做处理
      },
      false
    );
  },
};
</script>
<style scoped>
#app {
  box-sizing: border-box;
  background: #000;
  top: 0px;
  overflow: hidden;
  color: #fff;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

video {
  background: #000;
}

.videoWrapper {
  width: auto;
  border: 1px #eee solid;
  background-color: #333;
  box-sizing: border-box;
  min-width: 250px;

  overflow: hidden;
  height: 100%;
}

.videoWrapper video {
  position: absolute;
  z-index: 999;
  left: 0px;
  top: 0px;
  width: auto;
  height: 100%;
}
.videoControlBox {
  position: absolute;
  overflow: hidden;

  z-index: 9999;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  animation-fill-mode: forwards !important;
  transition: all 0.25s;
  color: #d2d2d2;
  height: 100%;
}

.icon-play {
  color: #d2d2d2;
  position: absolute;
  font-size: 45px;
  padding: 50px 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.icon-pause {
  color: #d2d2d2;
  position: absolute;
  left: 50%;
  padding: 50px 100px;
  top: 50%;
  font-size: 45px;
  transform: translate(-50%, -50%);
}
.icon-cover {
  color: #d2d2d2;
  position: absolute;
  z-index: 99999;
  left: 50%;
  padding: 50px 100px;
  top: 50%;
  font-size: 45px;
  transform: translate(-50%, -50%);
}

.videoControlBox .bottom {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  animation-fill-mode: forwards !important;
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 10px;
  text-align: center;
  left: 0px;
}

.videoControlBox .bottom .startTime {
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  width: 60px;
}
.videoControlBox .bottom .endTime {
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
  width: 60px;
}
.progress-box {
  display: flex;
  align-items: center;
  width: calc(100% - 60px);
  height: 2.5px;
  padding: 20px 0px;
}
.progress-line {
  position: relative;
  width: 100%;
  height: 2.5px;
  background-color: rgb(255, 255, 255, 0.2);
}
.playing-line {
  position: absolute;
  width: 0px;
  height: 2.5px;
  background-color: rgb(255, 255, 255, 0.5);
}
.buffer-line {
  width: 0px;
  position: absolute;
  height: 2.5px;
  background-color: rgb(255, 255, 255, 0.3);
}
.videoControlBox .header {
  position: relative;
  display: flex;
  color: #fff;
  justify-content: space-between;
  top: 0px;
  width: 100%;
  height: 60px;
}
.videoControlBox .download-btn {
  font-size: 16px;
  font-weight: bold;
  padding: 15px 45px;
}
</style>
<style>
@keyframes videoControlClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes videoControlOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes videoControlBottomClose {
  0% {
    bottom: 0px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    bottom: -100px;
  }
}

@keyframes videoControlBottomOpen {
  0% {
    opacity: 0;
    bottom: -100px;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.icon-back {
  margin-top: 10px;
  font-size: 150%;
}
.coverBox {
  background-color: #000;
  z-index: 999999;
  left: 0px;
  top: 0px;
  position: absolute;
  width: auto;
  height: 100%;
}
.coverBox img {
  opacity: 0.5;
  display: inline-block;
  background-color: #000;
  height: 100%;
  width: auto;
}
.videoBox {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #000;
  overflow: hidden;
}
.videoControlWrapper {
  position: relative;
  height: 100%;
}
</style>