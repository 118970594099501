import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue'),
        meta: {
            title: '30秒'
        }
    }, {
        path: '/About',
        name: 'About',
        component: () =>
            import ('../views/About.vue'),
        meta: {
            title: '了解30秒'
        }
    }, {
        path: '/Contact',
        name: 'Contact',
        component: () =>
            import ('../views/Contact.vue'),
        meta: {
            title: '联系我们'
        }
    }, {
        path: '/home/en',
        name: 'HomeEN',
        component: () =>
            import ('../views/Home.vue'),
        meta: {
            title: '30Sec'
        }
    },
    {
        path: '/share/:id',
        name: 'Share',
        component: () =>
            import ('../views/app/Share.vue'),
        meta: {
            title: '看广告享优惠'
        }
    }, {
        path: '/Advert/:id',
        name: 'Advert',
        component: () =>
            import ('../views/app/Advert.vue'),
        meta: {
            title: '看广告享优惠'
        }
    },
    {
        path: '/terms/privacypolicy',
        name: 'privacypolicy',
        component: () =>
            import ('../views/terms/Privacypolicy.vue'),
        meta: {
            title: '30秒隐私政策'
        }
    }, {
        path: '/terms/invitation',
        name: 'Invitation',
        component: () =>
            import ('../views/terms/Invitation.vue'),
        meta: {
            title: '“故事之地” - 30秒的邀请 “THE LAND OF STORY” - Invitation from 30sec '
        }
    }, {
        path: '/terms/down30About',
        name: 'down30About',
        component: () =>
            import ('../views/terms/down30About.vue'),
        meta: {
            title: '30秒是什么？'
        }
    }, {
        path: '/terms/testinvitation',
        name: 'TestInvitation',
        component: () =>
            import ('../views/terms/TestInvitation.vue'),
        meta: {
            title: '独特邀请独特'
        }
    }, {
        path: '/terms/affiliate',
        name: 'Affiliate',
        component: () =>
            import ('../views/terms/Affiliate.vue'),
        meta: {
            title: '独特寻找独特'
        }
    }, {
        path: '/terms/Reply/:id',
        name: 'Reply',
        component: () =>
            import ('../views/terms/Reply.vue'),
        meta: {
            title: '30秒'
        }
    }, {
        path: '/terms/testReply/:id',
        name: 'testReply',
        component: () =>
            import ('../views/terms/testReply.vue'),
        meta: {
            title: '30秒'
        }
    },
    {
        path: '/terms/useragreement',
        name: 'Useragreement',
        component: () =>
            import ('../views/terms/Useragreement.vue'),
        meta: {
            title: '30秒用户协议'
        }
    },
    {
        path: '/terms/BusinessAgreement',
        name: 'BusinessAgreement',
        component: () =>
            import ('../views/terms/BusinessAgreement.vue'),
        meta: {
            title: '30秒企业用户服务协议'
        }
    },

    {
        path: '/feedback',
        name: 'feedback',
        component: () =>
            import ('../views/app/Feedback.vue'),
        meta: {
            title: '一分钟使用访谈'
        }
    }, {
        path: '/app/test/:name',
        name: 'TestDownload1',
        component: () =>
            import ('../views/app/TestDownload.vue'),
        meta: {
            title: '30秒参与内测'
        }
    }, {
        path: '/app/test',
        name: 'TestDownload',
        component: () =>
            import ('../views/app/TestDownload.vue'),
        meta: {
            title: '30秒参与内测'
        }
    }, {
        path: '/app/download/:name',
        name: 'DownloadByName',
        component: () =>
            import ('../views/app/Download.vue'),
        meta: {
            title: '30秒下载'
        }
    }, {
        path: '/app/download',
        name: 'Download',
        component: () =>
            import ('../views/app/Download.vue'),
        meta: {
            title: '30秒下载'
        }
    }, {
        path: '/Brand/:id',
        name: 'Brand',
        component: () =>
            import ('../views/app/Brand.vue'),
        meta: {
            title: '我们的故事'
        }
    }, {
        path: '/demo',
        name: 'demo',
        component: () =>
            import ('../views/app/demo.vue'),
        meta: {
            title: '30sec - 视频播放器'
        }
    }, {
        path: '/router',
        name: 'router',
        component: () =>
            import ('../views/Router.vue'),
        meta: {
            title: '30sec - 路由'
        }
    }, {
        path: '/toCoupon',
        name: 'toCoupon',
        component: () =>
            import ('../views/toCoupon.vue'),
        meta: {
            title: ''
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router