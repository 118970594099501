<template>
  <div :style="setSize" class="wrapper item-flex justify-center align-center">
    <transition name="fade">
      <div v-show="showCoverSwitch" @click="showCoverFn" class="couponWrp">
        <div
          class="coverPic greyscale"
          :style="'background:url(' + adData.coverPicUrl + ')'"
        ></div>
        <div v-show="adData.coverPicUrl" class="cover-mask"></div>
        <div class="coupon">
          <div v-show="!checkBrandAdNameLength" class="header header1">
            {{ brandData.chineseName }}
            {{ brandData.englishName }}
            <div v-show="adData.name" style="margin-left: 5px" class="ad-title">
              {{ adData.name || "" }}
            </div>
            <span class="dur" v-show="adData.duration">
              {{ adData.duration + '"' }}</span
            >
          </div>

          <div v-show="checkBrandAdNameLength" class="header header2">
            <span>
              {{ brandData.chineseName }} {{ brandData.englishName }}
            </span>
            <br />
            <div class="ad-title-wrp">
              <div v-show="adData.name" class="ad-title">
                {{ adData.name || "" }}
              </div>
              <span class="dur" v-show="adData.duration">{{
                adData.duration + '"'
              }}</span>
            </div>
          </div>
          <div v-show="!brandData" class="header null-header">
            <!-- 用于显示骨架 -->
          </div>

          <!-- 优惠券组件 -->
          <div class="body">
            <CouponPre
              :adData="adData"
              :brandData="brandData"
              :couponData="couponData"
            ></CouponPre>
          </div>
        </div>
        <svg class="icon icon-pause" aria-hidden="true">
          <use xlink:href="#lefticon-play"></use>
        </svg>
      </div>
    </transition>
    <!-- 视频播放器 -->
    <transition name="fade">
      <video-pre
        x5-playsinline="" 
        playsinline=""
        webkit-playsinline=""
        poster=""
        x-webkit-airplay="allow"
        preload="auto"
        @back="showCoverFn()"
        ref="video"
        @ended="isShowOpen"
        v-show="!showCoverSwitch"
        :url="adData.contentVideoUrl"
      >
      </video-pre>
    </transition>
  </div>
</template>

<script>
/**
 * 组件说明
 *
 * 这是一个广告组件，用于在广告列表，内嵌套了优惠券组件
 *
 * 关于props
 * adData:这是广告实体的数据
 * brandData:这是品牌实体的数据
 * couponData:这是优惠券实体数据 （这是用于优惠券组件
 *
 * @date     2021 /04 /25
 * @author   Kamtao
 *
 */

import VideoPre from "./VideoPre.vue";
import CouponPre from "./couponPre2.vue";
export default {
  components: { CouponPre, VideoPre },
  props: {
    adData: {
      default: false,
    },
    brandData: {
      default: false,
    },
    couponData: {
      default: false,
    },
  },
  data() {
    return {
      showCoverSwitch: true,
    };
  },
  watch: {},
  computed: {
    /**
     * 检查品牌名和广告名是否超出长度 从而换行
     * ------------------------------------------------------------------
     */
    checkBrandAdNameLength() {
      if (
        this.adData &&
        this.adData.name &&
        this.brandData &&
        this.brandData.chineseName &&
        this.brandData.chineseName.length +
          this.brandData.englishName.length +
          this.adData.name.length >
          18
      ) {
        return true;
      } else {
        return false;
      }
    },

    /**
     * 设置一下组件的大小
     * ------------------------------------------------------------------
     */

    setSize: function () {
      let width = this.width;
      let height = this.height;
      return "width:" + width + "px;height:" + height + "px;";
    },
  },
  methods: {
    /**
     * 播放器设置
     * ------------------------------------------------------------------
     */

    showCoverFn() {
      var video = this.$refs.video;
      if (this.showCoverSwitch) {
        this.showCoverSwitch = false;
        this.$emit("hidOpenApp", true);
        video.clickPlay();
      } else {
        this.showCoverSwitch = true;
        this.$emit("hidOpenApp", false);
        video.clickPlay();
      }
    },

    isShowOpen() {
      this.$emit("isShowOpen");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.wrapper {
  overflow: hidden;
  position: relative;
  max-width: 1025px;
}
.cover {
  width: 100%;
  height: auto;
}
.showCoverBtn {
  width: 100%;
  height: 60px;
  text-align: right;
  padding: 5px 15px;
  box-sizing: border-box;
  z-index: 9999;
  position: absolute;
  top: 0px;
  left: 0px;
  color: #fff;
  font-size: 10px;
  background: #000000;
  background: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#000000),
    to(transparent)
  );
  background: -webkit-linear-gradient(#000000, transparent);
  background: -moz-linear-gradient(#000000, transparent);
  background: -ms-linear-gradient(#000000, transparent);
  background: -o-linear-gradient(#000000, transparent);
  background: linear-gradient(#000000, transparent);
  -pie-background: linear-gradient(#000000, transparent);
}

.couponWrp {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  cursor: pointer;
  background-color: #d1d1d1;
  position: absolute;
}

.coupon {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  font-size: 13px;
  transform: translateY(-60%) translateX(5%) rotate(-9deg);
  background-size: cover !important;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.coupon .body {
  display: flex;
  transform-origin: 0% 0%;
  transform: scale(0.58);
  white-space: nowrap;
  justify-content: start;
  align-items: center;
  width: 100vw;
  min-width: 600px;
}
.coupon .body .left {
  font-size: 220%;
  min-width: 80px;
  margin-left: 10px;
  font-weight: bold;
  flex-shrink: 0;
  text-align: right;
}

.coupon .body .midLine {
  width: 3px;
  height: 30px;
  flex-shrink: 0;
  margin: 0px 15px;
  background: #fff;
}
.coupon .body .right {
  font-weight: bold;
  flex-shrink: 0;
  font-size: 50%;
}
.coupon .left div {
  display: inline;
  white-space: nowrap;
}
.coupon .right div {
  display: inline;
}
.coupon .header {
  position: absolute;
  width: 100%;
  font-size: 16px;
  text-align: left;
  align-items: center;
  color: #fff;
  white-space: nowrap;
  transform: scale(0.8);
  transform-origin: 0px bottom;
}
.coupon .header1 {
  top: -30px;
  display: flex;
  align-items: center;
}
.coupon .header2 {
  top: -55px;
}
.coupon .header.null-header {
  background: #a5a5a5;
  width: 220px;
  height: 30px;
}
.coupon .header .left {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}
.coupon .header .left img {
  width: 30px;
  background-color: #fff;
  height: auto;
}
.coupon .header .right {
  display: flex;
  font-size: 12px;
  width: calc(100% - 40px);
  flex-direction: column;
}
.coupon .footer {
  width: 100%;
  margin-right: 50px;
  text-align: right;
  color: #fff;
}
.coverPic {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
}
.cover-mask {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
}

.ad-title {
  position: relative;
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ad-title-wrp {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 250px;
}

.dur {
  margin-left: 5px;
}
.icon-pause {
  position: absolute;
  bottom: 22px;
  left: 22px;
  color: #fff;
  opacity: 0.7;
  font-size: 240%;
  z-index: 99999;
}
@media screen and (max-width: 600px) {
  .couponWrp {
    width: 100vw;
    height: calc(100vw / 4 * 3.4);
  }
  .coupon {
    transform: translateY(calc(-50% + 17px)) translateX(-11%) rotate(-9deg);
  }
  .icon-pause {
    position: absolute;
  }
}
</style>