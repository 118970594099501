<template>
  <div class="main">
    <div v-if="brand" class="wrapper">
      <div
        id="downloadButton"
        v-show="!hidOpenAppTips"
        class="le-text-btn-underline white open-app-btn"
        :class="{ openAppDark: brand.story.theme == 1 }"
      >
        APP内打开
      </div>
      <div class="pc">
        <div
          :style="
            brand.storyStatus == 201
              ? 'background:#000;color:#fff'
              : brand.story.theme == 2
              ? 'color:#fff;background:#000'
              : 'color:#000;background:#fff'
          "
          class="logo-part"
          :class="{ logoPartDark: brand.story.theme == 2 }"
        >
          <img :src="brand.logoUrl" class="logo" />
        </div>
        <div
          :style="
            'color:' + brand.story.color + ';background:' + brand.story.bgColor
          "
        >
          <div v-show="!brand.story.content" class="null-story">
            <div class="content">故事还没有上演</div>
          </div>
          <div
            v-show="brand.story.content"
            class="story-item"
            :class="'content-' + item.type"
            v-for="(item, index) in brand.story.content"
            :key="index"
            :style="
              item.type == 103 ? 'background:url(' + item.bgImage + ')' : ''
            "
          >
            <div v-if="item.type == 103 || item.type == 301" class="mask"></div>

            <div v-if="item.type == 101" class="slogan">
              <icon class="iconfont icon-sloganLeft"></icon>
              <div
                :style="checkSloganIsCenter[index] ? 'text-align: center;' : ''"
                v-html="item.slogan"
              ></div>

              <icon class="iconfont icon-sloganRight"></icon>
            </div>
            <div v-if="item.type == 102 || item.type == 103" class="title">
              <div v-if="item.type == 103" class="subTitle">
                {{ item.subTitle }}
              </div>
              {{ item.title }}
            </div>
            <div v-if="item.type == 102" class="content">
              {{ item.content }}
            </div>

            <div v-if="item.type == 201" class="imgList">
              <img
                :key="index"
                :style="'width:calc(100% * ' + img.display + ')'"
                :src="img.url"
                v-for="(img, index) in item.imgList"
              />
            </div>
            <div v-if="item.type == 301" class="video">
              <!-- 	<video :poster="item.videoList[0].coverPicUrl" enable-play-gesture :src="item.videoList[0].url"
					controls></video> -->
              <img
                @click="toVideoPre(item.videoList[0].url)"
                class="icon"
                :src="icon_pause"
              />
              <img :src="item.videoList[0].coverPicUrl" mode="widthFix" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-show="isShowOpenTip"
        @click="isShowOpenTip = false"
        class="mask-box"
      >
        <p>点击「…」选择浏览器打开</p>
      </div>
    </transition>
    <!-- 视频播放器 -->
    <transition name="fade">
      <video-pre
        x5-playsinline=""
        playsinline=""
        webkit-playsinline=""
        poster=""
        x-webkit-airplay="allow"
        preload="auto"
        @back="isShowStory = true"
        ref="video"
        v-show="!isShowStory"
        :url="videoUrl"
      >
      </video-pre>
    </transition>
  </div>
</template>

<script>
const axios = require("axios");
import Swipe from "vant/es/swipe";
import SwipeItem from "vant/es/swipe-item";
import "vant/es/swipe/style";
import "vant/es/swipe-item/style";
import OpenInstall from "OpenInstall";
import whatPlatform from "../../util/whatPlatform";
import VideoPre from "../../components/VideoPre.vue";

export default {
  name: "brandStory",
  components: { VideoPre, [Swipe.name]: Swipe, [SwipeItem.name]: SwipeItem },
  data() {
    return {
      icon_pause: require("@/assets/img/icon-pause.png"),
      shareCover: "",
      brand: false,
      isTouch: true,
      isShowOpenTip: false,
      isShowStory: true,
      videoUrl: "",
      checkSloganIsCenter: [],
    };
  },

  methods: {
    toVideoPre: function (url) {
      this.videoUrl = url;
      this.isShowStory = false;
    },
    swipeEnd(index) {
      if (index == 1) {
        this.isTouch = false;
      }
    },
    storyScroll(e) {
      if (e.target.scrollTop <= 20) {
        this.isTouch = true;
      } else {
        this.isTouch = false;
      }
    },
    getShareCover: function () {
      for (let i = 0; i <= this.brand.story.content.length - 1; i++) {
        if (this.brand.story.content[i].type == 103) {
          this.shareCover = this.brand.story.content[i].bgImage;
          break;
        } else if (this.brand.story.content[i].type == 201) {
          this.shareCover = this.brand.story.content[i].imgList[0].url;
          break;
        } else if (this.brand.story.content[i].type == 301) {
          this.shareCover =
            this.brand.story.content[i].videoList[0].coverPicUrl;
          break;
        }
      }
    },
    getBrand() {
      var that = this;
      axios
        .get(
          "https://app.api.30sec.com.cn/v1/content/Brand/" +
            this.$route.params.id
        )
        .then(function (response) {
          // handle success
          var brand = response.data.data;
          if (brand.story && brand.story != "") {
            brand.story = JSON.parse(brand.story);
          } else {
            brand.story = {
              color: "#fff",
              bgColor: "#000",
              content: false,
            };
          }
          that.brand = brand;
          that.formatSloganIsCenter();
          that.getShareCover();
        });
    },
    // slogan是否居中
    formatSloganIsCenter() {
      var storyContentList = this.brand.story.content;
      for (let i = 0; i <= storyContentList.length - 1; i++) {
        if (storyContentList[i].type == 101) {
          if (this.getTextLength(storyContentList[i].slogan) <= 15) {
            this.checkSloganIsCenter[i] = true;
          }
        }
      }
    },

    /**
     * 获取字符长度
     * @param val
     */

    getTextLength(val) {
      let len = 0;
      for (let i = 0; i < val.length; i++) {
        let length = val.charCodeAt(i);
        if (length >= 0 && length <= 128) {
          len += 0.5;
        } else {
          len += 1;
        }
      }
      return Math.trunc(len);
    },
  },
  created() {
    this.getBrand();
  },
  mounted() {
    //OpenInstall初始化时将与openinstall服务器交互，应尽可能早的调用
    /*web页面向app传递的json数据(json string/js Object)，应用被拉起或是首次安装时，通过相应的android/ios api可以获取此数据*/
    var that = this;
    var data = {
      wakeType: 2,
      id: this.$route.params.id,
    };
    ///openinstall.js中提供的工具函数，解析url中的所有查询参数
    new OpenInstall(
      {
        /*appKey必选参数，openinstall平台为每个应用分配的ID*/
        appKey: "bgi3vk",
        /*自定义遮罩的html*/
        // mask: function () {
        //   return "<div id='_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'>1212212</div>";
        // },
        /*OpenInstall初始化完成的回调函数，可选*/
        onready: function () {
          /*在app已安装的情况尝试拉起app*/
          this.schemeWakeup();

          /*用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
          var m = this,
            button = document.getElementById("downloadButton");
          button.onclick = function () {
            if (whatPlatform() == "weibo") {
              that.isShowOpenTip = true;
            } else {
              m.wakeupOrInstall();
            }
            setTimeout(function () {
              that.isShowOpenTip = true;
            }, 2000);
            return false;
          };
        },
      },
      data
    );
  },
};
</script>

<style lang="scss" scoped>
@import url("../../assets/css/story_iconfont.css");

.main {
  background: #000;
}
.swipe-wrapper {
  width: 100%;
  height: 100vh;
  .swipe-item {
    height: 100vh !important;
    .logo-part {
      width: 100%;
      height: 100vh;
    }
    .story-content {
      height: 100vh !important;
      overflow-y: scroll;
    }
  }
}
.wrapper {
  color: #fff;
  max-width: 568px;
  margin: 0 auto;
  .openAppDark {
    color: #000;
  }
  .openAppDark::before {
    background: #000;
  }
  .logoPartDark {
    background: #000;
  }
  .logo-part {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      width: 130px;
      height: 130px;
      border-radius: 50%;
    }
  }
  .null-story {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      font-size: 17px;
      font-weight: bold;
      color: #999;
    }
  }
  .story-item {
    margin: 5px 0px;
  }
  .story-item:last-child {
    margin-bottom: 0px;
  }
  .story-item:nth-child(2) {
    margin-top: 0px;
  }
  .content-101 {
    min-height: 460px;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    .slogan {
      position: relative;
      width: 300px;
      font-size: 20px;
      line-height: 40px;
      white-space: pre-line;
      font-weight: bold;

      .iconfont {
        position: absolute;
        font-size: 22.5px;
      }

      .icon-sloganLeft {
        left: -22.5px;
        top: 0px;
        transform: translateY(-100%);
      }

      .icon-sloganRight {
        right: -22.5px;
        bottom: 0px;
        transform: translateY(100%);
      }
    }
  }

  .content-102 {
    margin: 80px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      width: 340px;
      font-size: 20px;
      white-space: pre-line;
      line-height: 30px;
      margin-bottom: 10px;
      font-weight: 900;
    }

    .content {
      width: 340px;
      font-size: 16px;
      white-space: pre-line;
      line-height: 24px;
    }
  }

  .content-103 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 240px;
    background-size: cover !important;
    background-position: center !important;
    margin-top: 50px;
    margin-bottom: 20px;

    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.2);
    }

    .subTitle {
      position: absolute;
      z-index: 9;
      white-space: pre-line;
      left: 50%;
      transform: translate(-50%, -120%);
      font-size: 16px;
      line-height: 16px;
      padding: 5px 10px;
      background-color: #fff;
      color: #000;
      font-weight: bold;
    }

    .title {
      position: absolute;
      left: 50%;
      white-space: pre-line;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      font-size: 26px;
      font-weight: bold;
      color: #fff;
      width: 80%;
      text-align: center;
      line-height: 40px;
    }
  }

  .content-201 {
    .imgList {
      padding: 0.5px 5px;
      display: flex;

      img {
        padding: 2.5px 5px;
        box-sizing: border-box;
      }
    }
  }

  .content-301 {
    position: relative;
    padding: 5px 10px;
    overflow: hidden;

    .mask {
      position: absolute;
      width: calc(100% - 20px);
      height: calc(100% - 14px);
      background: rgba($color: #000000, $alpha: 0.2);
    }

    img {
      width: 100%;
    }

    .icon {
      position: absolute;
      z-index: 999;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      padding: 20px;
      height: 40px;
    }
  }

  .share-box {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    .share-btn {
      transform: translateY(-60px);
      padding: 0px;
      font-size: 20px;
      border-radius: 0px;
      line-height: 30px;
      margin: 0px;
    }
  }
}
.open-app-btn {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  font-size: 18px;
  font-weight: bold;
  z-index: 9999;
}
.mask-box {
  position: fixed;
  z-index: 9999999;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.8);
  p {
    font-size: 15px;
    padding: 30px 20px;
    text-align: center;
    color: #d2d2d2;
  }
}
// .mobile {
//   display: none;
// }
// @media screen and (max-width: 850px) {
//   .mobile {
//     display: block;
//   }
//   .pc {
//     display: none;
//   }
// }
</style>
