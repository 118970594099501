<template>
  <div @touchstart="playStart()" class="wrapper-home">
    <Header />
    <transition name="slide">
      <div v-show="dialogDownload" class="dialog dialog-download">
        <svg @click="dialogDownload = false" class="icon icon-dialog-close">
          <use xlink:href="#lefticon-close"></use>
        </svg>
        <div class="main">
          <div
            v-show="isShowDownloadType == 0 || isShowDownloadType == 2"
            class="download ios"
          >
            <img
              src="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/home/ios-download.png"
              alt=""
              srcset=""
            />
            <div class="scan-tips">
              <div class="title">30秒APP</div>
              <div class="mes">
                手机扫码下载
              </div>
            </div>
          </div>
          <div
            v-show="isShowDownloadType == 1 || isShowDownloadType == 2"
            class="download miniApp"
          >
            <img
              src="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/home/miniApp-download.png"
              alt=""
              srcset=""
            />
            <div class="title">30秒微信小程序</div>
            <div class="scan-tips">截图或保存二维码，打开微信扫一扫</div>
          </div>
        </div>
      </div>
    </transition>

    <!-- <transition name="slide">
      <div
        @click="playFn(0), (dialogAlert = false)"
        v-show="dialogAlert"
        class="dialog mobile-alert"
      >
        <div class="tips">连Wifi 体验更好</div>
        <div class="le-text-btn-underline white">确定</div>
      </div>
    </transition> -->
    <div
      class="ad-box"
      v-for="(guide, index) in guideList"
      :key="'ad' + index"
      :class="{ isShowBox: isShowIndex == index }"
    >
      <div class="cover-mask"></div>
      <div class="coupon">
        <div class="header">
          <div class="title">看一个广告 拿一个优惠</div>
          <div
            @click="(dialogDownload = true), (isShowDownloadType = 2)"
            class="le-text-btn white btn"
          >
            试试看 &gt;
          </div>
        </div>
        <couponPre
          :adData="guide.advert"
          :brandData="guide.advert.brand"
          :couponData="guide.advert.coupon"
        ></couponPre>
      </div>

      <video
        :class="{ isShowCover: isShowIndex == index }"
        width="100%"
        height="100%"
        class="cover"
        object-fit="fill"
        :id="'video' + index"
        :src="guide.coverUrl"
        x5-playsinline=""
        playsinline=""
        webkit-playsinline=""
        poster=""
        x-webkit-airplay="allow"
        preload="auto"
        :muted="true"
      ></video>
      <div class="remark">
        <div class="mes">{{ guide.remark }}</div>
      </div>
    </div>
    <div class="btn-box">
      <div @click="download()" class="le-text-btn white">
        下载App
      </div>
      <div
        @click="(dialogDownload = true), (isShowDownloadType = 1)"
        class="le-text-btn white"
      >
        查看小程序
      </div>
    </div>
    <div class="footer">
      <div class="index-box-pc">
        <span
          @click="playFn(index)"
          :key="'pc' + index"
          v-for="(guide, index) in guideList"
          :class="setIndex(index)"
        >
          {{
            guide.advert.brand.showFirst == 1
              ? guide.advert.brand.chineseName || guide.advert.brand.englishName
              : guide.advert.brand.englishName || guide.advert.brand.chineseName
          }}
        </span>
      </div>
      <div class="index-box-mobile">
        <span
          @click="playFn(index)"
          :key="'m' + index"
          v-for="(guide, index) in guideList"
          :class="{ active: isShowIndex == index }"
        >
        </span>
      </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
import couponPre from "@/components/couponPre";
import Header from "@/components/Header";
import Copyright from "@/components/Copyright";
import axios from "axios";
import whatPlatform from "../util/whatPlatform";
import homeJson from "../util/home.json";

export default {
  components: { Header, couponPre, Copyright },
  props: {},
  data() {
    return {
      guideList: [],
      timerArr: [],
      isShowIndex: 0,
      isPlay: false,
      isShowDownloadType: 0, // 0 app 、 1 小程序、2 小程序和app
      coverTimer: "",
      dialogDownload: false,
      ls: "",
    };
  },
  watch: {},
  computed: {
    setIndex() {
      return function(index) {
        if (this.isShowIndex == index) {
          return "index-100";
        } else if (this.isShowIndex - 1 >= 0 && this.isShowIndex - 1 == index) {
          return "index-70";
        } else if (
          this.isShowIndex + 1 <= this.guideList.length - 1 &&
          this.isShowIndex + 1 == index
        ) {
          return "index-70";
        } else if (this.isShowIndex - 2 >= 0 && this.isShowIndex - 2 == index) {
          return "index-40";
        } else if (
          this.isShowIndex + 2 <= this.guideList.length - 1 &&
          this.isShowIndex + 2 == index
        ) {
          return "index-40";
        } else if (this.isShowIndex - 3 >= 0 && this.isShowIndex - 3 == index) {
          return "index-30";
        } else if (
          this.isShowIndex + 3 <= this.guideList.length - 1 &&
          this.isShowIndex + 3 == index
        ) {
          return "index-30";
        } else if (this.isShowIndex - 4 >= 0 && this.isShowIndex - 4 == index) {
          return "index-20";
        } else if (
          this.isShowIndex + 4 <= this.guideList.length - 1 &&
          this.isShowIndex + 4 == index
        ) {
          return "index-20";
        } else if (this.isShowIndex - 5 >= 0 && this.isShowIndex - 5 == index) {
          return "index-10";
        } else if (
          this.isShowIndex + 5 <= this.guideList.length - 1 &&
          this.isShowIndex + 5 == index
        ) {
          return "index-10";
        }
      };
    },
  },
  methods: {
    download() {
      if (whatPlatform() == "pc") {
        this.dialogDownload = true;
        this.isShowDownloadType = 0;
      } else {
        this.$router.push("/app/download");
      }
    },
    playStart() {
      if (this.isPlay == false) {
        this.isPlay = true;
        this.playFn(0);
      }
    },
    getGuideList() {
      var that = this;

      this.$api
        .GET("/v1/content/Guide", {
          platform: 4,
          pageSize: 100,
        })
        .then(function(res) {
          // 已经提交 反馈回APP
          if (res.code == 200) {
            that.guideList = res.data;
            setTimeout(function() {
              that.createdVideo();
            }, 100);
          }
        });
    },
    getGuideList2() {
      var that = this;
      that.guideList = homeJson.data;
      setTimeout(function() {
        that.createdVideo();
      }, 100);
    },
    playFn(index) {
      var that = this;
      var isElevideo = document.getElementById("video" + index);
      this.$nextTick(function() {
        for (let i = 0; i <= that.guideList.length - 1; i++) {
          let elevideo = document.getElementById("video" + i);
          elevideo.pause();
        }
        isElevideo.play();
      });
    },
    createdVideo() {
      this.$nextTick(function() {
        var that = this;
        for (let i = 0; i <= that.guideList.length - 1; i++) {
          let elevideo = document.getElementById("video" + i);
          if (i == 0) {
            that.isShowIndex = 0;
            elevideo.play();
          }

          elevideo.addEventListener(
            "ended",
            function() {
              elevideo.currentTime = 0;
              elevideo.pause();
              let elevideoNext = document.getElementById("video" + (1 + i));

              if (i == that.guideList.length - 1) {
                elevideoNext = document.getElementById("video" + 0);
              }
              elevideoNext.play();
            },
            false
          );

          elevideo.addEventListener(
            "play",
            function() {
              elevideo.currentTime = 0;
              that.isShowIndex = i;
            },
            false
          );
        }
      });
    },
  },
  created() {
    var that = this;
    var qrcode = that.$route.query.qrcode;
    if (qrcode) {
      axios
        .get("https://app.api.30sec.com.cn/v1/content/QRCode/" + qrcode)
        .then(function(res) {
          // 已经提交 反馈回APP
          if (res.data.code == 200) {
            if (res.data.data.type == 201) {
              that.$router.push("/app/download");
            }
          }
        });
    } else {
      this.getGuideList2();
    }
    (function() {
      var sUserAgent = navigator.userAgent;
      if (
        sUserAgent.indexOf("Android") > -1 ||
        sUserAgent.indexOf("iPhone") > -1 ||
        sUserAgent.indexOf("iPad") > -1 ||
        sUserAgent.indexOf("iPod") > -1 ||
        sUserAgent.indexOf("Symbian") > -1
      ) {
        that.isPlay = false;
      }
    })();
  },
  beforeUnmount() {
    //清除定时器
    clearInterval(this.coverTimer);
  },

  mounted() {},
};
</script>
<style lang="scss" scoped>
.wrapper-home {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background: #000;
  position: relative;
}

.ad-box {
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: 0;

  .cover {
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
  }

  .cover-mask {
    position: absolute;
    z-index: 98;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
  }
  .isShowCover {
    opacity: 1;
  }

  .ad {
    position: relative;
    z-index: 99999;
    width: 100%;
    height: 100%;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
  }

  .coupon {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    right: -380px;
    top: 47%;
    transform: translateY(-50%) rotate(-9deg) scale(1.24);
    z-index: 999;
    color: #fff;
  }
  .header {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0px;
    top: 0px;
    transform: translateY(-120%);
  }
  .header .title {
    font-size: 34px;
    font-weight: 900;
  }
  .header .btn {
    transform: scale(2.45);
    transform-origin: 0;
    margin-left: 65px;
    font-weight: 800;
  }
  .remark {
    position: absolute;
    z-index: 999;
    right: 40px;
    bottom: 40px;
    .mes {
      font-size: 12px;
      white-space: nowrap;
      margin-left: -6px;
      font-weight: 500;
      color: #dbdbdb;
      opacity: 0.41;
    }
  }
}
.btn-box {
  position: absolute;
  z-index: 999;
  right: 40px;
  bottom: 85px;
  display: flex;
  color: #fff;
}
.btn-box div {
  margin-right: 20px;
  font-size: 20px;
}
.btn-box div:last-child {
  margin-right: 0px;
}
.isShowBox {
  opacity: 1;
}
.footer {
  position: absolute;
  z-index: 99;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);

  .index-box-pc span {
    padding: 10px;
    color: #fff;
    transition: opacity 0.25s;
    opacity: 0.1;
    white-space: nowrap;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
  }
  .index-box-mobile {
    display: none;
  }
  .index-box-pc {
    position: relative;
    display: flex;
    margin-bottom: 40px;

    .index-100 {
      opacity: 1;
    }

    .index-70 {
      opacity: 0.7;
    }
    .index-40 {
      opacity: 0.4;
    }
    .index-30 {
      opacity: 0.3;
    }
    .index-20 {
      opacity: 0.2;
    }
    .index-10 {
      opacity: 0.1;
    }
  }
}

.dialog-download {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: rgba($color: #000000, $alpha: 0.9);
  .main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    padding: 50px;
    box-sizing: border-box;
    align-items: flex-start;
  }
  .download {
    display: flex;
    justify-content: center;
    padding: 50px;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
  }
  img {
    width: 300px;
    height: 300px;
  }
  .title {
    margin: 20px 0px 0px 0px;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
  }
  .scan-tips {
    font-size: 24px;
    text-align: center;
    color: #ffffff;
  }
  .scan-tips a {
    font-size: 24px;
  }
  .mes {
    margin-top: 5px;
    font-size: 24px;
    font-weight: 500;
    color: #dbdbdb;
    opacity: 0.41;
  }
}
// 手机端
@media screen and (max-height: 500px) {
  .wrapper-home {
    .btn-box {
      bottom: 65px;
    }
    .footer {
      bottom: 45px;
    }
    .ad-box {
      .remark {
        width: auto;
        right: 20px;
        bottom: 10px;
        text-align: left;
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .ad-box {
    .header {
      .title {
        font-size: 32px;
      }
      .btn {
        margin-left: 30px;
        transform: scale(1);
        font-size: 32px;
      }
    }
    .coupon {
      right: 20px;
      transform: scale(0.6) rotate(-9deg) translateY(-200%) translateX(40%);
      transform-origin: 100% 100%;
    }
    .remark {
      width: 100%;
      right: 0px;
      margin-bottom: 10px;
      .mes {
        font-size: 8px;
        text-align: center;
      }
    }
  }
  .btn-box {
    display: flex;
    justify-content: center;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 185px;
    display: flex;
    color: #fff;
  }
  .btn-box div {
    margin: 15px;
    font-size: 16px;
  }
  .btn-box div:last-child {
    margin-right: 15px;
  }

  .footer {
    bottom: 120px;
    .index-box-pc {
      display: none;
    }
    .index-box-mobile {
      display: flex;
    }
    .index-box-mobile span {
      margin: 3px;
      width: 5px;
      height: 5px;
      overflow: hidden;
      border-radius: 50%;
      background-color: #fff;
      opacity: 0.4;
    }
    .index-box-mobile span.active {
      opacity: 1;
    }
  }

  .dialog-download {
    flex-direction: column;
    .main {
      flex-direction: column;
      padding: 0px;
    }
    .download {
      width: 100%;
      display: flex;
      padding: 0px;
      justify-content: center;
      box-sizing: border-box;
      align-items: center;
      flex-direction: column;
    }
    .ios {
      margin-bottom: 80px;
    }

    img {
      width: 250px;
      height: 250px;
    }
    .title {
      margin: 20px 0px 0px 0px;
      font-size: 22px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
    }
    .scan-tips {
      font-size: 18px;
      color: #ffffff;
      text-align: center;
    }
    .scan-tips a {
      font-size: 18px;
      text-align: center;
    }
    .mes {
      text-align: center;
      margin-top: 5px;
      font-size: 18px;
    }
  }
  .mobile-alert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .tips {
      width: 70%;
      font-size: 16px;
      text-align: center;
      color: #fff;
      margin-bottom: 50px;
    }
    .le-text-btn-underline {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 579px) {
  .ad-box {
    .coupon {
      right: -120px;
    }
  }
}
</style>
