<template>
  <div class="wrapper">
    正在加载···
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    window.location.href = "https://testflight.apple.com/join/YAOWlrKn";
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100vw;
  padding: 10px;
  height: 100vh;
  background: #000;
  color: #fff;
  font-size: 14px;
  align-items: center;
}
</style>