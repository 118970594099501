<template>
  <div class="wrapper">
    <div v-for="(item, index) in routerList" :key="index">
      <router-link :to="item.path">{{ item.meta.title }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      routerList: [],
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    this.routerList = this.$router.options.routes;
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  padding: 50px 0px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  background: #000;
  color: #fff;
}
.wrapper div {
  width: 200px;
  margin: 10px;
}
.wrapper div a {
  color: #fff;
  padding: 10px;
  white-space: nowrap;
  text-decoration: underline;
  font-size: 22px;
}
</style>