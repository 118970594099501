<template>
  <div
    style="
      width: 100%;
      height: auto;
      padding: 20px 20px 20px 20px;
      box-sizing: border-box;
      background-color: #000;
      color: #fff;
    "
    class="wrapper"
  >
    <div style="max-width: 720px; color: #fff; margin: 0 auto" class="main">
      <div style="margin-bottom: 80px" class="cn">
        <div>
          <img
            style="
              height: auto;
              background: #000;
              margin-bottom: 30px;
              color: #fff;
              width: 180px;
            "
            src="https://oss.30sec.com.cn/public/email/topteis.png"
          />
        </div>
        <div>
          <img
            style="
              height: auto;
              background: #000;
              color: #fff;
              margin-bottom: 35px;
              width: 200px;
            "
            src="https://oss.30sec.com.cn/public/email/title.png"
          />
        </div>
        <div
          style="
            font-size: 15px;
            font-weight: 400;
            color: #fff;
            line-height: 28px;
          "
        >
          正如第一个写出喜剧的作家，期待他的第一批读者一样，我们向你发出了邀请。<br />
          看上去是一款App的内测，实质是我们的产品思想、愿景、模式、美学在寻找最早的共鸣。<br />
          我们认为，世界上还有如此之多的美好之物（充满独特魅力的品牌），只是你不了解而已；广告其实并不是一种干扰，只是没有安静观看的驱动力和氛围而已；消费还有其非物质性的一面，只是你没有体验到而已。<br />
          我们的产品模式是：看广告享优惠。<br />
          我们试图建立，先了解品牌，再购买产品的消费路径。我们认为，这将使你成为品牌消费者，这将使消费超越物理性。<br />
          第一个写出喜剧的作家可能没有想到读者从中体验到巨大的悲悯，我们也难以预测一款没有标杆的产品会遭遇什么样的体验，我们期待你的参与和反馈（你将在使用App的过程中收到反馈问卷）。我们期待的绝不仅仅是找到一些物理性的bug，我们更为期待的是通过你的意见修复我们构想上的bug，无论如何，从你打开30秒开始，你已成为我们这个独特故事的一部分。
          <br /><br />
          邀请你下载30秒，了解我们。<br />
          点击以下链接 - 下载TestFlight - 安装Beta版30秒App -
          参与内测（目前仅限于iOS用户）<br />
          <a
            style="color: #fff; text-decoration: underline"
            href="https://30sec.com.cn/app/test/30sec"
          >
            https://30sec.com.cn/app/test/30sec</a
          >
          <br />
          <br /><br />
          致以最美好的祝福<br />
          30秒团队
        </div>
      </div>
      <div class="en">
        <div
          style="
            font-weight: bolder;
            font-size: 28px !important;
            line-height: 38px;
            display: inline;
            background: #000;
            color: #fff;
          "
        >
          Uniqueness Inviting Uniqueness
        </div>
        <div
          style="
            font-size: 15px;
            margin-top: 22px;
            color: #fff;
            font-weight: 400;
            line-height: 24px;
          "
        >
          As the first comedy writer who expects his first reader, we send this
          invitation to you.<br />
          Seems like we are launching a test of our App, in essence we are
          looking for the initial resonance with the philosophy, mission, model
          and aesthetic of our product. <br />We believe, there are so many
          beautiful things (unique brands) in the world, you just don't realize;
          there are also commercial videos actually not annoying, you just don't
          have a motivation and environment to watch them serenely; and there is
          a non-material side of consumption, you just have not experienced
          it.<br />
          Our product model is: watch the commercial video and enjoy the
          discount.<br />
          We try to build a consuming path, which is understanding the brand
          before purchasing products. We believe it will make you a brand
          consumer, and make the brand beyond physicality.<br />
          Just as the first comedy writer didn't expect his readers would feel a
          huge compassion from his work, it's hard to expect a product without
          benchmark will face what kind of experience. We are looking forward to
          your participation and feedback (you will receive a questionnaire
          during use). Far more than the physical bugs of App, we are looking
          forward to your suggestions to fix the bugs of our conception. Anyhow,
          since you open our 30sec App, you have already become a part of our
          unique story.
          <br /><br />
          Download 30sec, know more about us.<br />
          Click the following link - Download TestFlight - Install Beta 30sec
          App - Participate in testing (currently available for iOS users)<br />
          <a
            style="color: #fff; text-decoration: underline"
            href="https://30sec.com.cn/app/test/30sec"
          >
            https://30sec.com.cn/app/test/30sec</a
          ><br /><br /><br />Best Regards <br />30sec Team<br />
        </div>
      </div>

      <div
        style="margin-top: 40px; margin-bottom: 50px; text-align: center"
        class="intro-box"
      >
        <div
          style="
            text-align: center;
            width: 100%;
            max-width: 320px;
            padding: 10px 0px;
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <img
            style="
              height: auto;
              display: inline-block;
              margin: 0px auto;
              width: 160px;
            "
            src="https://oss.30sec.com.cn/public/email/p-title-1.png"
          />

          <img
            style="
              width: 100%;
              display: inline-block;
              margin: 0px;
              padding: 0px 10px;
              box-sizing: border-box;
              height: auto;
            "
            src="https://oss.30sec.com.cn/public/email/email-footer-1.png?x-oss-process=style/email_invite"
          />
        </div>
        <div
          style="
            text-align: center;
            width: 100%;
            max-width: 320px;
            padding: 10px 0px;
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <img
            style="height: auto; width: 180px"
            src="https://oss.30sec.com.cn/public/email/p-title-2.png"
          />

          <img
            style="
              width: 100%;
              margin: 0px;
              display: inline-block;
              padding: 0px 10px;
              box-sizing: border-box;
              height: auto;
            "
            src="https://oss.30sec.com.cn/public/email/email-footer-2.png?x-oss-process=style/email_invite"
          />
        </div>
        <div
          style="
            text-align: center;
            width: 100%;
            max-width: 320px;
            padding: 10px 0px;
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <img
            style="height: auto; display: inline-block; width: 200px"
            src="https://oss.30sec.com.cn/public/email/p-title-3.png"
          />

          <img
            style="
              margin: 0px;
              display: inline-block;
              padding: 0px 10px;
              box-sizing: border-box;
              width: 100%;
              height: auto;
            "
            src="https://oss.30sec.com.cn/public/email/email-footer-3.png?x-oss-process=style/email_invite"
          />
        </div>
        <div
          style="
            text-align: center;
            width: 100%;
            max-width: 320px;
            padding: 10px 0px;
            box-sizing: border-box;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <img
            style="height: auto; display: inline-block; width: 175px"
            src="https://oss.30sec.com.cn/public/email/p-title-4.png"
          />

          <img
            style="
              margin: 0px;
              padding: 0px 10px;
              display: inline-block;
              box-sizing: border-box;
              width: 100%;
              height: auto;
            "
            src="https://oss.30sec.com.cn/public/email/email-footer-4.png?x-oss-process=style/email_invite"
          />
        </div>
      </div>
      <div
        style="height: auto; color: #777; padding-top: 20px"
        class="qrcode-box"
      >
        <img
          style="width: 100px; margin: 0px; padding: 0px; height: auto"
          src="https://oss.30sec.com.cn/public/email/testQrcode.png"
        />
        <div style="margin-top: 10px; font-size: 13px">
          扫描二维码 - 下载TestFlight - 安装Beta版30秒App
        </div>
        <div style="font-size: 13px">
          Scan QR code - Download TestFlight - Install Beta 30sec App
        </div>
      </div>
      <div style="padding-top: 180px" class="footer">
        <img
          width="70"
          style="
            width: 70px;
            background: #000;
            margin-bottom: 30px;
            height: auto;
          "
          class="logo"
          src="https://oss.30sec.com.cn/public/email/logo.png"
        />
        <div style="font-size: 13px; line-height: 22px">
          前往我们的官网，了解更多<a
            style="color: #fff !important; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/?test=v1.0.0"
            >30sec.com.cn</a
          ><br />
        </div>
        <div style="font-size: 13px; line-height: 22px; margin-bottom: 40px">
          Go to our official website, know more about us
          <a
            style="color: #fff !important; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/home/en?test=v1.0.0"
          >
            <span style="color: #fff !important"> 30sec.com.cn</span></a
          >
          <br />

          Email:
          <a
            style="
              color: #fff;
              text-decoration-color: #fff;
              text-decoration: underline;
            "
            target="_blank"
            href="Mailto:info@30sec.com.cn"
          >
            <span style="color: #fff !important"> info@30sec.com.cn </span></a
          ><br />Guangzhou, Guangdong, China 510220(P.C.)
        </div>
        <div style="font-size: 12px; color: #777777; line-height: 18px">
          如无法浏览图片内容，请<a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/testInvitation"
            >点击此处</a
          >。<br />
          版权所有 ©LeFt ©2020 30sec。保留所有权利。<br />
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/useragreement"
            >用户协议</a
          >
          ｜
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/privacypolicy"
            >隐私政策</a
          >
        </div>

        <div
          style="
            font-size: 12px;
            line-height: 18px;
            color: #777777;
            margin-bottom: 10px;
          "
        >
          If you are not able to browse the images, please
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/testInvitation"
          >
            click here</a
          >.<br />
          Copyright ©LeFt ©2020 30sec. All rights reserved.<br />
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/useragreement"
            >Users Agreement</a
          >
          ｜
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/privacypolicy"
            >Privacy Policy</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
