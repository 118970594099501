<template>
  <div class="video-wrapper">
    <div class="videoWrapper">
      <video
        x5-playsinline=""
        playsinline=""
        webkit-playsinline=""
        poster=""
        x-webkit-airplay="allow"
        preload="auto"
        id="video"
        :src="url"
        type="video/mp4"
      ></video>
      <div @click="backAdPre()" class="icon-back">
        <svg class="icon icon-back" aria-hidden="true">
          <use xlink:href="#lefticon-back"></use>
        </svg>
      </div>
      <div
        @click="playControl()"
        :style="controlShowStyle"
        class="videoControlBox"
      >
        <div :style="controlBottomShowStyle" class="bottom">
          <div class="startTime">{{ video.startTime }}"</div>
          <div
            @click.stop="clickVideo($event)"
            ref="progressBox"
            class="progress-box"
          >
            <div class="progress-line">
              <div
                :style="'width:' + video.bufferLine + '%;'"
                class="buffer-line"
              ></div>
              <div
                :style="'width:' + video.playingLine + '%;'"
                class="playing-line"
              ></div>
            </div>
          </div>
          <div class="endTime">{{ video.endTime }}"</div>
        </div>
        <div class="icon-control">
          <svg
            :style="controlIconStyle"
            @click.stop="clickPlay"
            v-if="video.status == 'playing'"
            class="icon icon-play"
            aria-hidden="true"
          >
            <use xlink:href="#lefticon-pause"></use>
          </svg>
          <svg
            :style="controlIconStyle"
            @click.stop="clickPlay"
            v-if="['pause', 'ended', 'waiting'].indexOf(video.status) >= 0"
            class="icon icon-pause"
            aria-hidden="true"
          >
            <use xlink:href="#lefticon-play"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    url: {
      type: String,
      default: function () {
        return "https://vod.30sec.com.cn/434155812fbf4f23a9362c0f996109e7/a0020998f6604514a7a3ea058354005f-7f6be0b0973c9a50cb4e8fee3647266c-ld-nbv1.mp4";
      },
    },
  },
  data() {
    return {
      showTimer: "",
      controlShowStyle: "",
      controlIconStyle: "",
      controlBottomShowStyle: "",
      clickCount: 0,
      videoControlStatus: true,
      video: {
        status: "pause",
        startTime: 0,
        endTime: 0,
        bufferLine: 0,
        playingLine: 0,
      },
    };
  },
  watch: {
    videoControlStatus: function (val) {
      if (val) {
        this.controlShowStyle = "animation: videoControlOpen 0.25s;";
        this.controlIconStyle = "z-index:99";
        this.controlBottomShowStyle =
          "animation: videoControlBottomOpen 0.25s;";
      } else {
        this.controlShowStyle = "animation: videoControlClose 0.25s;";
        this.controlIconStyle = "z-index:-9;";
        this.controlBottomShowStyle =
          "animation: videoControlBottomClose 0.25s;";
      }
    },
  },
  computed: {},
  methods: {
    clickVideo(e) {
      var that = this;
      var video = document.getElementById("video");
      var progressTime =
        (e.offsetX / this.$refs.progressBox.offsetWidth) * video.duration;

      clearTimeout(that.showTimer);
      this.videoControlStatus = true;
      this.showTimer = setTimeout(() => {
        that.videoControlStatus = false;
        clearTimeout(that.showTimer);
      }, 3500);
      video.currentTime = progressTime;
    },
    backAdPre() {
      var video = document.getElementById("video");
      video.pause();
      this.$emit("back");
    },
    playControl() {
      var that = this;
      this.clickCount += 1;
      setTimeout(function () {
        if (that.clickCount == 1) {
          if (that.videoControlStatus) {
            that.videoControlStatus = false;
          } else {
            that.videoControlStatus = true;
            that.showTimer = setTimeout(() => {
              that.videoControlStatus = false;
            }, 3500);
          }
          that.clickCount = 0;
        } else if (that.clickCount == 2) {
          that.clickCount = 0;
          that.clickPlay();
        }
      }, 250);
    },
    clickPlay() {
      var video = document.getElementById("video");
      if (this.video.status == "playing") {
        video.pause();
      } else {
        video.play();
      }
    },
  },
  created() {
    var that = this;
    that.videoControlStatus = true;
    // handle success

    this.$nextTick(function () {
      var video = document.getElementById("video");
      video.addEventListener("loadedmetadata", function () {
        that.video.endTime = Math.floor(video.duration);
      });
      video.addEventListener("waiting", function () {
        //加载
        console.log("加载中");
      });
      video.addEventListener("play", function () {
        //播放开始执行的函数
        that.video.status = "playing";
        setTimeout(() => {
          that.videoControlStatus = false;
        }, 2500);
      });
      video.addEventListener("pause", function () {
        //暂停开始执行的函数
        that.video.status = "pause";
        setTimeout(() => {
          that.videoControlStatus = false;
        }, 3500);
      });
      video.addEventListener(
        "ended",
        function () {
          //结束
          that.controlShowStyle = "animation: videoControlOpen 0.5s;";
          that.controlBottomShowStyle =
            "animation: videoControlBottomOpen 0.5s;";
          that.video.status = "ended";
          that.videoControlStatus = false;
          that.$emit("ended");
        },
        false
      );

      //使用事件监听方式捕捉事件

      video.addEventListener(
        "timeupdate",
        function () {
          try {
            //用秒数来显示当前播放进度
            that.video.playingLine = (video.currentTime / video.duration) * 100;
            that.video.startTime = Math.floor(video.currentTime);
            that.video.bufferLine =
              (video.buffered.end(video.buffered.length - 1) / video.duration) *
              100;
          } catch {
            console.log("视频加载中");
          }
          //当视频播放到 4s的时候做处理
        },
        false
      );
    });
  },
  mounted() {},
};
</script>
<style scoped  lang="scss">
#app {
  box-sizing: border-box;
  background: #000;
  top: 0px;
  overflow: hidden;
  color: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

video {
  background: #000;
}
.video-wrapper {
  position: fixed;
  z-index: 99999;
  overflow: hidden;
  left: 0px;
  top: 0px;
  overflow: hidden;
  background-color: #000000;
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
}
.videoWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.videoWrapper video {
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.videoControlBox {
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  animation-fill-mode: forwards !important;
  transition: all 0.25s;
  width: 100%;
  color: #d2d2d2;
  height: 100%;
}
.icon-control {
  position: relative;
  width: 100%;
  height: 100%;
}
.icon-play {
  color: #d2d2d2;
  position: absolute;
  padding: 20px;
  font-size: 45px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.icon-pause {
  padding: 20px;
  color: #d2d2d2;
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 45px;
  transform: translate(-50%, -50%);
}
.videoControlBox .bottom {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  animation-fill-mode: forwards !important;
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 20px;
  text-align: center;
  left: 0px;
  z-index: 99999999;
}

.videoControlBox .bottom .startTime {
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  width: 60px;
}
.videoControlBox .bottom .endTime {
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
  width: 60px;
}
.progress-box {
  display: flex;
  align-items: center;
  width: calc(100% - 60px);
  height: 2.5px;
  padding: 20px 0px;
}
.progress-line {
  position: relative;
  width: 100%;
  height: 2.5px;
  background-color: rgba(255, 255, 255, 0.2);
}
.playing-line {
  position: absolute;
  width: 0px;
  height: 2.5px;
  background-color: rgba(255, 255, 255, 0.5);
}
.buffer-line {
  width: 0px;
  position: absolute;
  height: 2.5px;
  background-color: rgba(255, 255, 255, 0.3);
}
.videoControlBox .header {
  position: relative;
  display: flex;
  color: #fff;
  justify-content: space-between;
  top: 0px;
  width: 100%;
  height: 60px;
}
.videoControlBox .download-btn {
  font-size: 16px;
  font-weight: bold;
  padding: 15px 45px;
}

.downloadWrapper {
  color: #fff;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.downloadWrapper .logo {
  width: 70px;
  height: auto;
}
.downloadWrapper .download-app {
  color: #fff;
  padding: 20px 30px 0px 30px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
}
</style>
<style>
@keyframes videoControlClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes videoControlOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes videoControlBottomClose {
  0% {
    bottom: 0px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    bottom: -100px;
  }
}

@keyframes videoControlBottomOpen {
  0% {
    opacity: 0;
    bottom: -100px;
  }
  100% {
    opacity: 1;
    bottom: 20px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.icon-back {
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 150%;
  color: #fff;
  z-index: 999999;
}
</style>