<template>
  <div class="wrapper">
    <div class="main">
      <div class="title">30秒企业用户服务协议</div>
      <p>30秒企业用户服务协议 本协议更新时间：2021年5月14日</p>
      <br />
      <p>
        欢迎使用30秒。《30秒企业用户服务协议》（以下简称“本协议”）是30秒就企业用户（以下或简称“用户”）使用30秒提供的互联网服务（包括但不限于：30秒网站www.30sec.com.cn、30秒App、30秒小程序等）所订立的相关权利义务规范。本协议对用户和30秒均具有法律效力。本协议连同所有更新材料、补充条款以及30秒的其他规则和政策共同构成用户与30秒之间的协议。
      </p>
      <br />

      <p class="boldtext">
        在阅读本协议时，请用户特别留意加粗内容。若完整阅读、理解并同意本协议的内容，用户可以开始使用30秒；用户对30秒的下载、安装、注册、登录等使用行为将被视作对本协议全部条款的理解和接受。<br />
        如用户对本协议存在疑问或有意见需要向30秒反馈，请联系30秒客服邮箱；<br />
        如用户不同意本协议中所述任何条款或其后对协议条款的修改，请停止使用30秒，并按以下方式联系客服申请终止协议：<br />
      </p>
      <p>邮箱：business@30sec.com.cn</p>
      <br />

      <h4 class="boldtext">一． 服务内容</h4>
      <p class="boldtext">1. 概述：</p>
      <ul></ul>
      <li>
        <span class="rank">1.1</span>
        30秒是由广州市左边广告有限公司开发和全权持有的一款品牌广告和优惠券展示平台。企业用户可在30秒展示广告和优惠，消费者可在30秒观看广告获得优惠，并前往品牌及品牌入驻电商平台使用优惠。消费者所购商品或服务由品牌及品牌入驻电商平台向用户提供，交易关系在品牌、电商平台和消费者之间建立。
      </li>
      <li>
        <span class="rank">1.2</span>
        30秒提供服务的具体内容由30秒根据实际情况决定，30秒保留随时变更、中断或终止部分或全部服务的权利。除非本协议另有其他明示规定，调整、增加或者强化30秒服务中的任何功能，包括所推出的新产品，均受到本协议之规范。
      </li>
      <li>
        <span class="rank">1.3</span>
        30秒将根据中华人民共和国相关法律法规的要求对30秒网站上的广告、优惠、商品等信息进行合理审核，但无法保证设置的外部链接的准确性和完整性，同时对于该等外部链接指向的不由30秒实际控制的任何网页上的内容，均不承担任何责任。
      </li>
      <h4 class="boldtext">2. 用户注册</h4>
      <p style="margin-left: 20px" class="boldtext">
        企业用户如需在30秒展示广告和优惠券，应通过30秒官网（www.
        30sec.com.cn）的“企业入口”申请入驻，完成注册及企业认证，并确保资料的真实、合法、有效。如用户及企业资料发生变更，请及时在企业客户端网页进行修改，并提交审核。在完成注册程序后，用户应维护账号及密码的机密安全。用户应对任何人利用用户的账号及密码所进行的活动完全负责，30秒无法对非法或未经用户授权使用用户账号及密码的行为作出甄别，因此30秒将不承担任何责任。
      </p>
      <br />
      <ul style="margin-left: 20px">
        <p>用户注册及企业认证所需资料或将涉及：</p>
        <li><span class="rank">2.1</span>品牌名称</li>

        <li><span class="rank">2.2</span> 品牌简介</li>
        <li><span class="rank">2.3</span> 品牌官网</li>
        <li><span class="rank">2.4</span> 品牌logo</li>
        <li>
          <span class="rank">2.5</span> 品牌所属行业（用于向用户定向推送内容）
        </li>
        <li><span class="rank">2.6</span> 企业名称</li>
        <li>
          <span class="rank">2.7</span>
          企业社会信用代码（用于确认企业信息真实有效）
        </li>
        <li>
          <span class="rank">2.8</span>
          企业营业执照或其他补充材料（用于确认企业信息真实有效）
        </li>
        <li>
          <span class="rank"> 2.9</span>
          企业有效银行账户与开户行信息（用于确认企业信息真实有效及或将产生的增值服务费用等）
          上述信息均需真实、有效，属于同一企业、组织和/或法人。
        </li>
        <br />
        <p>同时用户同意并承诺做到：</p>
        <li>
          <span class="rank"> 2.10</span>
          当账号或密码遭到未经授权的使用，或者发生任何安全问题时，用户会立即有效地通知到30秒；
        </li>
        <li>
          <span class="rank"> 2.11</span>
          当用户每次登录30秒或使用相关服务后，会将有关账号等安全退出；
        </li>
        <li>
          <span class="rank">2.12</span>
          用户同意接受30秒通过电子邮件、网页客户端、手机短信或其他合法方式向用户发送通知信息和其他相关信息；
        </li>
        <li>
          <span class="rank"> 2.13</span>
          用户在此同意，30秒有权对违反本协议条款的用户作出禁止注册及/或封号的处理。
        </li>
        <li>
          <span class="rank"> 2.14 </span>
          用户了解并知悉，用户可以其30秒账号登录30秒及其关联方提供的30秒体系下全部产品。
        </li>
      </ul>
      <br />
      <h4 class="boldtext">3. 上传和发布内容</h4>
      <ul style="margin-left: 20px">
        <p class="boldtext">
          3.1 用户承诺在30秒上传和发布的内容符合以下法律法规：
        </p>

        <li>
          <span class="rank"> 3.1.1</span>《中华人民共和国保守国家秘密法》
        </li>
        <li><span class="rank"> 3.1.2</span> 《中华人民共和国广告法》</li>
        <li><span class="rank"> 3.1.3</span> 《中华人民共和国著作权法》</li>
        <li>
          <span class="rank"> 3.1.4 </span
          >《中华人民共和国计算机信息系统安全保护条例》
        </li>

        <li><span class="rank"> 3.1.5 </span>《计算机软件保护条例》</li>
        <li><span class="rank"> 3.1.6 </span>《互联网电子公告服务管理规定》</li>
        <li><span class="rank"> 3.1.7</span> 《信息网络传播权保护条例》</li>
        <li><span class="rank"> 3.1.8</span> 《中华人民共和国网络安全法》</li>
        <li>
          <span class="rank"> 3.1.9</span>
          其他有关计算机及互联网规定的法律、法规。
        </li>
        <br />
        <p>
          3.2
          30秒会以程序、人工等方式对用户上传的内容进行审核。如内容存在违法、违反平台规则等问题，用户上传的内容可能无法通过审核。
        </p>
        <br />
        <p>3.3 禁止用户通过30秒平台传播以下内容，包含但不限于：</p>
        <li>
          <span class="rank"> 3.3.1</span>

          任何反对宪法所确定的基本原则、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益等法律、行政法规禁止的内容或其他令人反感的内容；
        </li>
        <li>
          <span class="rank"> 3.3.2</span>

          虚假宣传、夸大产品实际功效、极端化描述（如“第一”、“最”及其谐音和替代性表达）；
        </li>
        <li>
          <span class="rank"> 3.3.3</span>

          违背国际普世价值观的内容，如宣扬狭隘民族主义、歧视和污名化特定族群（如按种族、国籍、地域、性别、年龄、性取向划分的特定人群，或身患疾病、残疾的人群）的内容；
        </li>
        <li><span class="rank"> 3.3.4 </span> 价值取向和趣味低下的内容；</li>
        <li>
          <span class="rank"> 3.3.5</span>
          可能造成对动物的虐待的内容（如马戏表演）；
        </li>
        <li>
          <span class="rank"> 3.3.6</span>

          任何其他可能对观看者造成侵害、骚扰或影响观看者身心健康的内容（如简单重复的“洗脑式”内容等）。
          含有以上信息的视频或内容可能无法通过30秒的审核，也可能随时被30秒下架。30秒拥有对平台内容规范的最终解释权，并不承担用户因违反内容规范所产生的损失。
        </li>

        <p>3.4 内容的反馈机制：</p>

        <li>
          <span class="rank"> 3.4.1</span>

          30秒用户在观看企业用户展示的视频内容时，可以通过“喜欢”按钮进行反馈，每位用户对每支视频内容分别可点按1-3次“喜欢”按钮。
        </li>
        <li>
          <span class="rank"> 3.4.2</span>
          30秒用户在App的意见反馈页内，可对广告内容或优惠信息提出相关意见或投诉举报，30秒将依据用户提供信息重新审核企业用户所发布的内容，并有权对相关内容进行暂停展示、下架或永久下架处理，且不承担企业因此而造成的任何损失。
        </li>
      </ul>
      <br />
      <h4 class="boldtext">4. 发布优惠券</h4>
      <ul style="margin-left: 20px">
        <p class="boldtext">
          4.1
          企业用户可为广告视频绑定优惠券，由30秒定向导流至企业自有或企业入驻的电商平台，以便消费者进行购买。
        </p>

        <p>4.2 真实可用：</p>
        <li>
          <span class="rank"> 4.2.1</span>
          <span class="boldtext"> 企业用户应对优惠券的真实可用性负责。</span
          >如优惠券可在实体店铺使用，并且是产品定向专用优惠券，在上线优惠券前需确认所涉产品库存。
        </li>
        <li>
          <span class="rank"> 4.2.2</span>
          为保障消费者权益，30秒会对企业优惠券进行审核，确认优惠券真实可用、价格合理。如审核不通过，30秒有权不上线相关优惠券。如企业多次违反平台优惠券相关规定，30秒有权暂停或中断服务。
        </li>
        <p class="boldtext">
          4.3
          企业用户在30秒设置优惠券信息时，可提供企业在天猫/淘宝、京东、官网或其他电商平台上已预先设置的优惠券链接或优惠代码，供30秒技术接入优惠信息及核销情况。如企业希望以优惠券以外的形式发布优惠，请联系30秒商务邮箱表明需求。
        </p>
        <p>
          4.4
          <span class="boldtext">
            企业用户在提供优惠券时应注意设置合理的优惠有效期限及优惠券数量。一般情况下不允许提前终止优惠券的发放，以免损害消费者权益。</span
          >如企业用户确实因特殊原因（如优惠券的价格、数量和日期设置不合理、账户遭盗用）需要提前重新修改优惠券信息，30秒将对优惠进行重新审核，申请通过后方可向消费者展示相关修改操作，但需对已领券用户进行相应的解释或补偿。
        </p>
        <p class="boldtext">
          4.5
          企业用户应根据所涉行业及产品价格，设置合理且具有独家竞争力的优惠内容（包括但不限于：超低折扣、全网独家优惠、新品首发优惠、从未打折的产品的优惠等），并自行承担相关责任。
        </p>
        <p class="boldtext">
          4.6
          企业用户应确保消费者在30秒上领取的优惠内容，可与购买平台和店铺内其他优惠活动叠加使用。
        </p>
      </ul>
      <br />
      <ul>
        <h4 class="boldtext">二． 用户信息安全</h4>
        <li>
          <span class="rank">1.</span>
          30秒尊重和保护用户隐私，承诺将按业界适用的安全标准保护用户信息。
        </li>
        <li>
          <ul>
            <span class="rank">2. </span>
            30秒不会与任何组织、公司或个人共享用户信息，但以下情况除外：

            <li><span class="rank">2.1 </span>获得用户明确授权的；</li>
            <li>
              <span class="rank">2.2 </span
              >根据相关法律法规，30秒负有披露义务的；
            </li>
            <li>
              <span class="rank">2.3 </span>司法和行政机关基于法定程序要求的；
            </li>
            <li> 
              <span class="rank">2.4 </span>
              与30秒的关联公司和授权合作伙伴共享有限范围内的用户信息，以保证用户顺畅使用30秒的产品和服务。
            </li>
          </ul>
        </li>
        <li>
          <span class="rank"> 3. </span>
          企业用户应建立制度和措施保障企业在30秒的信息安全，例如：分派专人保管和管理企业在30秒的账户，合理控制管理员权限范围，谨防信息泄露。
        </li>
      </ul>
      <br />
      <ul>
        <h4 class="boldtext">三． 知识产权</h4>
        <li>
          <span class="rank"> 1.</span>
          30秒的所有版本、技术、程序和所有的信息内容（包括但不限于产品功能、用户界面、操作步骤、商标、专利、计算机软件作品等）的知识产权均归30秒及其关联方、许可方所有，30秒及其关联公司对用户的软件使用许可在任何情况下均不应视为向用户转让其知识产权的部分或者全部。用户通过30秒观看的信息内容，仅限个人娱乐、学习、研究或者欣赏使用，未经30秒书面同意，不可用于任何商业用途。
        </li>
        <li>
          <span class="rank"> 2.</span>
          用户同意，将其在30秒上发布的全部内容（包括已发布及未来可能发布的内容）授予30秒在全球范围内免费的、不可撤销的、非独家、可转授权的使用许可，以使用、复制、修改、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、读物、报道或技术中；30秒保证其任何使用、修改、衍生创作均不会歪曲、篡改用户表达的原意，或故意使用任何可能引发侵权的内容；同时30秒有权将上述内容在与30秒合作的第三方网站中使用或者授权给该等第三方网站使用。30秒依据本款约定使用用户发布的内容时，无需另行取得用户的事先同意。
        </li>
        <li>
          <span class="rank"> 3.</span>
          30秒平台的视频作品的所有权利均属于作者，请勿传播、复制、改编、修改或以其他方式不当使用他人视频作品，包括但不限于转载、链接、下载、复制、编辑、修改、变更、上传、发行、表演、衍生其他作品、创作衍生品、诋毁、破坏作品技术保护措施等。
        </li>
      </ul>
      <br />
      <ul>
        <h4 class="boldtext">四． 收费服务</h4>
        <li>
          <span class="rank"> 1.</span>
          因应30秒产品及服务的发展情况，30秒或将对企业用户收取交易佣金或对部分增值服务收取费用，各项费用以网页客户端相关页面具体信息和最终确认的数据为准。
        </li>
        <li>
          <span class="rank"> 2.</span>
          在提供收费服务前，30秒会向企业用户给予明确提示，只有在根据提示选择同意支付相关费用后，30秒才会提供该等收费服务。如企业用户拒绝支付相关费用，30秒将停止收取相关费用手续操作，并有权不再试图或继续提供该等收费服务。
        </li>
        <li>
          <span class="rank"> 3. </span
          >如企业使用收费增值服务，请遵守相关的专项规则。
        </li>
      </ul>
      <br />
      <ul>
        <h4 class="boldtext">五． 免责条件</h4>
        <li>
          <span class="rank">1.</span>
          请从30秒指定的途径和站点下载30秒服务相关软件。30秒无法保证非官方途径软件的安全（例如是否感染病毒或装有木马），也不对用户因下载非官方途径30秒软件所造成的直接或间接损失负责。
        </li>
        <li>
          <span class="rank"> 2.</span>
          <ul>
            <p>
              用户知悉并同意，发生以下事件时，30秒不承担因无法正常提供服务所造成的用户数据资料、收益等方面的有形或无形损失：
            </p>
            <li>
              <span class="rank"> 2.1</span>
              因台风、地震、海啸、洪水、泥石流等自然灾害或停电、战争、恐怖袭击、疫情大流行等不可抗力因素导致服务不能正常运作。
            </li>
            <li>
              <span class="rank">2.2</span>
              由于黑客攻击、电信部门技术调整或故障、系统维护等原因而造成的系统服务中断或者延迟。
            </li>
            <li>
              <span class="rank">2.3</span>
              由于政府命令、法律法规的变更、司法机关及行政机关的命令、裁定等原因而导致的系统服务中断、终止或延迟。
            </li>
            <li>
              <span class="rank"> 2.4</span>
              由于与30秒链接或合作的其它网站（如网上银行等）所造成的银行帐户信息、身份信息泄露及由此而导致的任何法律争议和后果。
            </li>
            <li>
              <span class="rank"> 2.5</span>
              如因系统维护或升级的需要而需暂停服务时，我们将尽可能事先进行通知。对于服务的中断或终止而给用户造成的任何损失，我们无须对用户或任何第三方承担任何责任。
            </li>
          </ul>
        </li>
        <li>
          <span class="rank"> 3.</span>
          用户了解并知悉，30秒所提供的视频内容及优惠信息部分来自于第三方公开渠道或各大电商平台的公开营销推广计划，30秒在使用并展示上述内容前将提前以电子邮件等方式告知视频所有者或优惠主办方，并保证对其中任何内容的合法、正当呈现，如视频所有者或优惠主办方对上述使用或展示行为存有异议，请联系30秒停止使用。
        </li>
      </ul>
      <br />
      <ul>
        <h4 class="boldtext">六． 协议的修改</h4>
        <li class="boldtext">
          <span class="rank"> 1.</span>
          30秒有权依据国家政策、技术条件、产品功能的变化需要而对本协议进行修改并将修改后的协议予以发布。协议修改并发布后，30秒将会以弹窗、站内信、公告等形式提醒用户阅读和了解协议的修改内容。
        </li>
        <li class="boldtext">
          <span class="rank"> 2.</span>
          修改后的内容将构成本协议不可分割的组成部分，用户应同样遵守。用户的使用行为将被视为对修改内容的阅读、理解与接受。
        </li>
        <li class="boldtext">
          <span class="rank"> 3.</span>
          如对修改内容有疑问或建议，请按本协议中所列的联系方式与我们联系。
        </li>
      </ul>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
* {
  font-family: "Noto Sans SC";
  margin: 0px;
  padding: 0px;
  font-weight: 400;
  font-size: 1.5rem;
}

html {
  font-size: 62.5%;
}

body {
  background: #000;
  font-size: 1.4rem;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

a {
  color: #fff;
}

.wrapper {
  width: 100%;
  height: auto;
  background: #000;
}
.wrapper .main {
  width: 100%;
  min-height: 100vh;
  max-width: 1000px;
  font-size: 1.2rem;

  text-align: justify;
  box-sizing: border-box;
  line-height: 24px;
  color: #b4b4b4;
  height: auto;
  margin: 0 auto;
  padding: 30px 80px;
}

li {
  list-style: none;
  padding-left: 10px;
  margin: 5px 0px;
  margin-left: 50px;
  line-height: 22px;
  position: relative;
}

li .rank {
  width: 30px;
  text-align: right;
  position: absolute;
  left: -30px;
}
section {
  margin: 40px auto;
}

section h4 {
  margin-bottom: 10px;
}

.unicon li {
  list-style: none;
  margin-left: 0px;
}

.title {
  width: 100%;
  font-weight: 900;
  text-align: center;
  margin: 50px 0px;
  font-size: 22px;
}

.boldtext {
  font-weight: 900;
}

.boldtext li {
  font-weight: 900;
}
@media screen and (max-width: 569px) {
  .wrapper .main {
    padding: 30px;
  }
}
</style>