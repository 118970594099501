import { createApp } from 'vue'
// 引入组件
import App from './App'
// 引入资源
import router from './router'

// 创建app
const app = createApp(App)


// 引入饿了么UI
// import 'element-ui/lib/theme-chalk/index.css'

/* 引入全局CSS */
import '@/assets/css/reset.css' // 重置样式
import '@/assets/css/global.css' // 全局样式
import '@/assets/css/compatible.css' // 响应式兼容


// 引入util
import tool from '@/util/tool.js'
app.config.globalProperties.$tool = tool;


/* 全局请求axios */
import api from '@/axios/api' //这里引入的是js文件  
app.config.globalProperties.$api = api;

/* 全局请求axios */
import buss from '@/axios/buss' //这里引入的是js文件 
app.config.globalProperties.$buss = buss;

/* 全局工具 */
import checkStr from '@/util/checkStr.js' //这里引入的是js文件

app.config.globalProperties.$checkStr = checkStr;
// 注入路由
app.use(router)



// 加入百度统计
router.beforeEach((to, from, next) => {


        /* 路由发生变化修改页面title */
        if (to.meta.title) {
            document.title = to.meta.title
        }
        if (to.path) {
            if (window._hmt) {
                window._hmt.push(['_trackPageview', '' + to.fullPath])
            }
        }
        next()
    })
    // 挂载实例
app.mount('#app')