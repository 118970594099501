<template>
  <div
    style="
      width: 100%;
      letter-spacing: -0.5px;
      min-height: 100vh;
      height: auto;
      background-color: #000;
      color: #fff;
    "
  >
    <div
      style="
        background-color: #000;
        max-width: 500px;
        padding: 50px 15px;
        margin: 0 auto;
      "
      class="wrapper"
    >
      <table align="center" style="width: 100%">
        <tbody>
          <tr>
            <td>
              <img
                width="70"
                style="
                  width: 50px;
                  background: #000;
                  margin-bottom: 30px;
                  height: auto;
                "
                class="logo"
                src="https://oss.30sec.com.cn/public/email/logo.png"
              />
            </td>
          </tr>
          <tr>
            <td
              style="
                font-size: 13px;
                font-weight: 400;
                color: #fff;
                line-height: 24px;
              "
            >
              在喧嚣嘈杂的移动互联网，在哪里讲述品牌故事？<br />

              如何回避干扰？<br />
              如何确保广告被看到？<br />
              如何确保广告被看完？<br />
              如何不被跳过、被静音、被VIP去广告、被软件拦截、<br />
              被机器人看、被数据造假？<br />
              如何做到广告 百分百到达 ？ <br />
              来了解30秒！<br />
              了解我们突破性的解决之道！ <br />
              了解我们如何做到 免费投放广告，并实现 全渠道导流 ！<br />
              了解我们如何将塑造品牌（故事）和效果（购买）有机结合！<br />
              了解我们如何帮助你塑造品牌！<br />
              <br />
              <br />

              深入了解，点击获取
              <a
                style="
                  color: #fff;
                  white-space: nowrap;
                  text-decoration: none;
                  border-bottom: 1px #fff solid;
                "
                target="_blank"
                href="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/business/30秒是什么？.pdf"
              >
                <span style="color: #fff">《30秒是什么?》 </span></a
              >或咨询 <span style="color: #fff">business@30sec.com.cn </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table style="margin-top: 120px">
        <tbody>
          <tr>
            <td>
              <div style="font-size: 12px; color: #777777">
                前往我们的官网，了解更多<a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn"
                  >30sec.com.cn</a
                ><br />
              </div>
              <div style="font-size: 12px; color: #777777; margin-bottom: 40px">
                Go to our official website, know more about us
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn"
                >
                  <span style="color: #777777"> 30sec.com.cn</span></a
                >
                <br />

                Email:
                <a
                  style="
                    color: #777777;
                    text-decoration-color: #777777;
                    text-decoration: underline;
                  "
                  target="_blank"
                  href="Mailto:info@30sec.com.cn"
                >
                  <span
                    style="
                      color: #777777;
                      text-decoration-color: #777777;
                      text-decoration: underline;
                    "
                  >
                    business@30sec.com.cn
                  </span></a
                ><br />Guangzhou, Guangdong, China 510220(P.C.)
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div style="font-size: 12px; color: #777777">
                如无法浏览图片内容，请<a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/down30About"
                  >点击此处</a
                >。<br />
                版权所有 ©LeFt ©2020 30sec。保留所有权利。<br />
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/useragreement"
                  >用户协议</a
                >
                ｜
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/privacypolicy"
                  >隐私政策</a
                >
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div style="font-size: 12px; color: #777777; margin-bottom: 10px">
                If you are not able to browse the images, please
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/down30About"
                >
                  click here</a
                >.<br />
                Copyright ©LeFt ©2020 30sec. All rights reserved.<br />
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/useragreement"
                  >Users Agreement</a
                >
                ｜
                <a
                  style="color: #777777; text-decoration: underline"
                  target="_blank"
                  href="https://www.30sec.com.cn/terms/privacypolicy"
                  >Privacy Policy</a
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
