<template>
  <div class="wrapper"></div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {
    location.href = "weixin://dl/business/?t=qk97JqWmlZc";
  },
};
</script>
<style lang="scss" scoped>
</style>