<template>
  <div class="wrapper">
      <iframe src="https://www.baidu.com/"   frameborder="0" style="width:100%;height:100%; position:absolute;top:0px;left: 0px;"></iframe>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      routerList: [],
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
 height: 100vh;
 overflow: hidden;

}

</style>