<template>
  <div class="wrapper">
    <videoTool
      :key="index"
      class="leftVideo"
      :coverUrl="coverUrl"
      :videoUrl="videoUrl"
    ></videoTool>
  </div>
</template>

<script>
import videoTool from "../../components/videoTool";
export default {
  components: { videoTool },
  props: {},
  data() {
    return {
      coverUrl:
        "https://vod.30sec.com.cn/f425fc0c9fd141fabe701d974d66f9c6/snapshots/3f8fa65c8c0346c18cd312f282f5bc0e-00002.jpg",
      videoUrl:
        "https://vod.30sec.com.cn/f425fc0c9fd141fabe701d974d66f9c6/02a76db708884bfca50849fe5fe5a492-d6c66d682a3f64657cccd77c896c5dfc-sd.mp4",
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative; display: flex;
  width: auto;
  height: 500px;
}
</style>