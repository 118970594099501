<template>
  <div class="wrapper">
    <transition name="fade">
      <ad-pre
        @isShowOpen="isShowOpen"
        :adData="adData"
        :brandData="adData.brand"
        :couponData="adData.coupon"
        @hidOpenApp="hidOpenApp"
      >
      </ad-pre>
    </transition>
    <div
      v-if="isShowOpenTip2 && !isShowOpenTip3"
      @click="(isShowOpenTip2 = false), (isShowOpenTip3 = false)"
      class="icon-back"
    >
      <svg class="icon icon-back" aria-hidden="true">
        <use xlink:href="#lefticon-back"></use>
      </svg>
    </div>
    <div
      id="downloadButton"
      v-show="!hidOpenAppTips"
      class="le-text-btn-underline white open-app-btn"
    >
      APP内打开，看广告享优惠
    </div>
    <transition name="fade">
      <div v-show="isShowOpenTip2" @click="closeTip2" class="mask-box">
        <p v-show="isShowOpenTip3">点击「…」选择浏览器打开</p>
        <div v-show="!isShowOpenTip3" class="open-app-box">
          <img
            width="70"
            src="https://30sec.oss-cn-guangzhou.aliyuncs.com/public/logo.svg"
            alt=""
          />
          <div class="le-text-btn-underline white" id="downloadButton2">
            去APP看，即拿优惠
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-show="isShowOpenTip"
        @click="isShowOpenTip = false"
        class="mask-box"
      >
        <p>点击「…」选择浏览器打开</p>
      </div>
    </transition>
  </div>
</template>

<script>
const axios = require("axios");
import AdPre from "../../components/AdPre.vue";
import OpenInstall from "OpenInstall";
import whatPlatform from "../../util/whatPlatform";

export default {
  components: { AdPre },
  props: {},
  data() {
    return {
      adData: {},
      hidOpenAppTips: false,
      isShowOpenTip: false,
      isShowOpenTip2: false,
      isShowOpenTip3: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getAdData() {
      var that = this;
      axios
        .get(
          "https://app.api.30sec.com.cn/v1/content/Advert/" +
            this.$route.params.id
        )
        .then(function (response) {
          // handle success
          that.adData = response.data.data;
        });
    },
    isShowOpen() {
      this.isShowOpenTip2 = true; // 打开提示
    },
    closeTip2() {
      if (this.isShowOpenTip3) {
        this.isShowOpenTip2 = false;
        this.isShowOpenTip3 = false;
      }
    },
    hidOpenApp(status) {
      this.hidOpenAppTips = status;
    },
  },
  created() {
    this.getAdData();
  },
  mounted() {
    //OpenInstall初始化时将与openinstall服务器交互，应尽可能早的调用
    /*web页面向app传递的json数据(json string/js Object)，应用被拉起或是首次安装时，通过相应的android/ios api可以获取此数据*/
    var that = this;
    var data = {
      wakeType: 1,
      id: this.$route.params.id,
    };
    ///openinstall.js中提供的工具函数，解析url中的所有查询参数
    new OpenInstall(
      {
        /*appKey必选参数，openinstall平台为每个应用分配的ID*/
        appKey: "bgi3vk",
        /*自定义遮罩的html*/
        // mask: function () {
        //   return "<div id='_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'>1212212</div>";
        // },
        /*OpenInstall初始化完成的回调函数，可选*/
        onready: function () {
          /*在app已安装的情况尝试拉起app*/
          this.schemeWakeup();

          /*用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
          var m = this,
            button = document.getElementById("downloadButton"),
            button2 = document.getElementById("downloadButton2");
          button.onclick = function () {
            if (whatPlatform() == "weibo") {
              that.isShowOpenTip = true;
            } else {
              m.wakeupOrInstall();
            }

            return false;
          };
          button2.onclick = function () {
            if (whatPlatform() == "weibo") {
              setTimeout(function () {
                that.isShowOpenTip3 = true;
              }, 50);
            } else {
              m.wakeupOrInstall();
            }

            return false;
          };
        },
      },
      data
    );
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  max-width: 1025px;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background: #000;
  display: flex;
  align-items: center;

  box-sizing: border-box;
}
.mask-box {
  position: fixed;
  z-index: 9999999;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.8);
  p {
    font-size: 15px;
    padding: 30px 20px;
    text-align: center;
    color: #d2d2d2;
  }
}
.open-app-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  font-size: 18px;
  font-weight: bold;
  z-index: 9999;
}
.open-app-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .le-text-btn-underline {
    font-size: 18px;
  }
  img {
    margin-bottom: 20px;
  }
}
.icon-back {
  position: absolute;
  top: 15px;
  left: 10px;
  font-size: 150%;
  color: #fff;
  z-index: 9999999999;
}
@media screen and (max-width: 500px) {
  .wrapper {
    padding-bottom: 30px;
  }
}
</style>