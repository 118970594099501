<template>
  <div v-show="!isShowOpenTip" class="wrapper">
    正在跳转下载···
  </div>
  <div v-show="isShowOpenTip" class="mask-box">
    <p>点击「…」选择浏览器打开下载App</p>
    <img class="logo" src="../../assets/img/logo.svg" />
  </div>
</template>

<script>
import util from "../../util/tool";

export default {
  components: {},
  props: {},
  data() {
    return {
      isShowOpenTip: false,
      platform: "",
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    var platform = util.getPlatform();
    this.platform = platform;
    var az_url =
      "https://30sec.oss-cn-guangzhou.aliyuncs.com/public/APPZIP/30sec.apk";

    var t = new Date().getTime();
    var ios_url = "https://itunes.apple.com/cn/app/id1544200325";
    if (
      platform.app == "wechat" &&
      (platform.system == "android" || platform.system == "qq")
    ) {
      this.isShowOpenTip = true;
    } else if (platform.system == "android") {
      window.location.href = az_url + "?v=" + t + "&&temp=1";
    } else if (platform.system == "ios") {
      window.location.href = ios_url + "?v=" + t + "&&temp=1";
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100vw;
  padding: 10px;
  box-sizing: border-box;
  height: 100vh;
  background: #000;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask-box {
  position: fixed;
  z-index: 9999999;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 1);
  p {
    font-size: 15px;
    padding: 30px 20px;
    text-align: center;
    color: #d2d2d2;
  }
}

.logo {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  transform: translate(-50%, -50%);
}
</style>
