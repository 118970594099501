<template>
  <div
    style="
      width: 100%;
      min-height: 100vh;
      padding: 20px 20px 20px 20px;
      box-sizing: border-box;
      background-color: #000;
      color: #fff;
    "
    class="wrapper"
  >
    <div style="max-width: 520px; color: #fff; margin: 120px auto" class="main">
      <div class="footer">
        <a href="https://30sec.com.cn">
          <img
            width="70"
            style="
              width: 80px;
              background: #000;
              margin-bottom: 30px;
              height: auto;
            "
            class="logo"
            src="https://oss.30sec.com.cn/public/email/logo.png"
        /></a>
        <div style="font-size: 13px; line-height: 22px">
          <h1
            style="
              font-weight: 600;
              line-height: 35px;
              margin-top: 60px;
              font-size: 22px;
            "
          >
            {{ userMes.title }}
          </h1>
          <p style="font-size: 15px; margin-top: 20px">
            {{ userMes.msg }}
          </p>
          <br />
          <div class="le-text-btn-underline white" @click="toPath()">
            点击查看
          </div>
        </div>

        <div
          style="
            font-size: 12px;
            color: #777777;
            margin-top: 100px;
            line-height: 18px;
          "
        >
          版权所有 ©LeFt ©2021 30秒。保留所有权利。<br />
          前往官网了解更多
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn"
            >30sec.com.cn
          </a>
          ｜
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/privacypolicy"
          >
            隐私政策</a
          >
        </div>
        <br />

        <div
          style="
            font-size: 12px;
            line-height: 18px;
            color: #777777;
            margin-bottom: 10px;
          "
        >
          Copyright ©LeFt ©2021 30sec. All rights reserved.<br />
          For more information
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn"
            >30sec.com.cn
          </a>
          ｜
          <a
            style="color: #777777; text-decoration: underline"
            target="_blank"
            href="https://30sec.com.cn/terms/privacypolicy"
            >Privacy Policy</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");

// @ is an alias to /src
export default {
  name: "Home",
  components: {},
  data() {
    return {
      userMes: {
        title: "这是标题",
        msg: "这是内容",
        type: 3104,
      },
    };
  },
  watch: {},
  methods: {
    toPath() {
      var path = "http://business.30sec.com.cn/#/";
      switch (this.userMes.type) {
        // 跳转目标： 
        // 3000 企业端  无
        // 3104 企业端品牌认证相关  跳转到  品牌认证页
        // 3105 企业端 广告发布相关  跳转到发布广告页
        // 3106 企业端 广告发布相关 跳转到广告管理页草稿箱
        // 3107 企业端 广告发布相关 跳着到广告管理页已发布

        case 3000:
          break;
        case 3104:
          path = path + "brandInfo";
          break;
        case 3105:
          path = path + "UpAdInitiative";
          break;
        case 3106:
          path = path + "DraftList";
          break;
        case 3107:
          path = path + "AdList";
          break;

        default:
          break;
      }

      window.open(path, "_blank");
    },
  },
  created() {
    var that = this;
    axios
      .get(
        "https://buss.api.30sec.com.cn/v1/EnterpriseUser/UserMsg/" +
          that.$route.params.id
      )
      .then(function (res) {
        // 已经提交 反馈回APP
        if (res.data.code == 200) {
          that.userMes = {
            title: res.data.data.title,
            msg: res.data.data.msg,
            type: res.data.data.type,
          };
        }
      });
  },
  mounted() {},
  beforeUnmount() {},
};
</script>

