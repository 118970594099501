<template>
  <div class="wrapper-home">
    <Header />

    <Copyright />
  </div>
</template>

<script>
import Copyright from "@/components/Copyright";
import Header from "@/components/Header";

export default {
  components: { Header, Copyright },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.wrapper-home {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background: #000;
  position: relative;
}
</style>