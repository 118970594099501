<template>
  <div class="wrapper">
    <div class="main">
      <svg @click="quit" class="icon icon-closeXbold" aria-hidden="true">
        <use xlink:href="#lefticon-closeXbold"></use>
      </svg>
      <h1>一分钟使用访谈</h1>
      <div class="form">
        <!-- 多选题 -->
        <div v-for="(item, itemIndex) in form" :key="itemIndex">
          <h4>
            {{ itemIndex + 1 + "、" + item.title
            }}{{ item.type == 2 ? "（多选）" : "" }}
          </h4>

          <!-- 简答题 -->
          <div v-if="item.type == 1">
            <textarea v-model="item.value"></textarea>
          </div>

          <!-- 多选题 -->
          <div v-if="item.type == 2">
            <div
              :style="
                item.layout == 1
                  ? 'display:flex; flex-wrap: wrap;justify-content: space-between;'
                  : ''
              "
              class="label-wrapper"
            >
              <div
                :style="item.layout == 1 ? 'width:calc(50% - 40px);' : ''"
                class="label-box"
                v-for="(option, optionIndex) in item.options"
                :key="optionIndex"
              >
                <input
                  v-show="false"
                  :id="'left' + itemIndex + optionIndex"
                  type="checkbox"
                  v-model="item.value"
                  :value="option"
                />
                <label :for="'left' + itemIndex + optionIndex">
                  {{ option }}
                </label>
              </div>
            </div>
            <transition name="slide">
              <div v-show="checkOtherSwitch(item.value, item.otherSwitch)">
                <textarea
                  v-model="item.otherValue"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </transition>
          </div>

          <!-- 单选题 -->
          <div v-if="item.type == 3">
            <div class="label-wrapper">
              <div
                class="label-box"
                v-for="(option, optionIndex) in item.options"
                :key="optionIndex"
              >
                <input
                  v-show="false"
                  :id="'left' + itemIndex + optionIndex"
                  type="radio"
                  v-model="item.value"
                  :value="option"
                />
                <label :for="'left' + itemIndex + optionIndex">
                  {{ option }}
                </label>
              </div>
            </div>
            <transition name="slide">
              <div v-show="checkOtherSwitch(item.value, item.otherSwitch)">
                <textarea
                  placeholder="为什么？"
                  v-model="item.otherValue"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </transition>
          </div>
        </div>

        <div class="submit-box">
          <div class="submit btn-under-line" @click="submit">
            提交
          </div>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div v-show="dialogOnceAgain" class="dialog dialog-onceAgain">
        <svg
          @click="closeWeb()"
          class="icon icon-closeXbold"
          aria-hidden="true"
        >
          <use xlink:href="#lefticon-closeXbold"></use>
        </svg>
        <div class="mes">
          再来点建议？<span
            @click="dialogOnceAgain = false"
            class="btn-under-line"
            >继续</span
          >
        </div>
        <div class="ties">*更多意见和反馈请前往 设置 - 意见反馈</div>
      </div>
    </transition>
    <transition name="slide">
      <div v-show="dialogConfirm" class="dialog dialog-confirm">
        <div class="mes">把选择题填完吧？</div>
      </div>
    </transition>
    <transition name="slide">
      <div
        v-show="dialogSubmitSuccessfully"
        class="dialog dialog-submitSuccessfully"
      >
        <svg
          @click="close('true')"
          class="icon icon-closeXbold"
          aria-hidden="true"
        >
          <use xlink:href="#lefticon-closeXbold"></use>
        </svg>
        <div class="mes">提交成功</div>
        <div class="ties">*更多意见和反馈请前往 设置 - 意见反馈</div>
      </div>
    </transition>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogSubmitSuccessfully: false,
      dialogConfirm: false,
      dialogOnceAgain: false,
      routeQuery: {
        versionCode: "",
        osType: "",
        userId: ""
      },
      form: [
        {
          // 类型：1-简答题  2-多选题 3-单选题
          // 收集：选择是否收集简答 other 对应 选项
          title: "30秒对你最大的价值？",
          type: 2,
          options: [
            "了解到很多品牌",
            "找到喜欢的风格",
            "看广告",
            "拿优惠",
            "其他"
          ],
          value: [],
          otherSwitch: ["其他"], // 如果长度大于零开启下面的多选框
          otherValue: ""
        },
        {
          title: "你使用30秒的单次时长？",
          type: 3,
          options: ["5分钟以下", "5 - 15分钟", "15 - 30分钟", "30分钟以上"],
          value: "",
          otherSwitch: [],
          otherValue: ""
        },
        {
          title: "你最棒的感觉是什么？",
          type: 1,
          value: ""
        },
        {
          title: "你最不棒的感觉是什么？",
          type: 1,
          value: ""
        },
        {
          title: "你愿意继续用吗？",
          type: 3,
          options: ["愿意", "不愿意"],
          value: "",
          otherSwitch: ["愿意", "不愿意"],
          otherValue: ""
        },
        {
          title: "希望你推荐给朋友",
          type: 3,
          options: ["好", "不好"],
          value: "",
          otherSwitch: [],
          otherValue: ""
        },
        {
          title:
            "我们认为先了解品牌再购买产品是更成熟更未来的消费模式，你认同吗？",
          type: 3,
          options: ["认同", "不认同"],
          value: "",
          otherSwitch: [],
          otherValue: ""
        },
        {
          title: "你遇到的异常情况",
          type: 2,
          layout: 1,
          options: [
            "没有异常",
            "闪退",
            "无法登录",
            "界面错乱",
            "视频无法播放",
            "加载过慢",
            "无法领券",
            "手机发热/耗电快",
            "其他"
          ],
          value: [],
          otherSwitch: ["其他"],
          otherValue: ""
        },
        {
          title: "你最想谈的",
          type: 1,
          value: ""
        }
      ]
    };
  },
  watch: {},
  computed: {
    checkOtherSwitch: function() {
      return function(value, otherSwitch) {
        if (Array.isArray(value) && value.length > 0) {
          for (let i = 0; i <= value.length; i++) {
            if (otherSwitch.indexOf(value[i]) >= 0) {
              return true;
            }
          }
        } else if (value != "") {
          if (otherSwitch.indexOf(value) >= 0) {
            return true;
          }
        } else {
          return false;
        }
      };
    }
  },
  methods: {
    checkForm(type) {
      var _form = JSON.parse(JSON.stringify(this.form));
      // 没填完
      if (type == 101) {
        for (let i = 0; i <= _form.length - 1; i++) {
          if (
            (_form[i].type == 2 && _form[i].value.length == 0) ||
            (_form[i].type == 3 && _form[i].value == "")
          ) {
            return false;
          }
        }
        return true;
      } else if (type == 201) {
        // 都没填
        var c = 0;
        for (let i = 0; i <= _form.length - 1; i++) {
          if (
            (_form[i].type == 2 && _form[i].value.length == 0) ||
            (_form[i].type == 3 && _form[i].value == "")
          ) {
            c += 1;
          }
        }
        if (c == 6) {
          return false;
        } else {
          return true;
        }
      }
    },
    closeWeb() {
      var that = this;
      if (this.checkForm(201)) {
        axios
          .post("https://app.api.30sec.com.cn/v1/common/CommFeedback/", {
            type: 501,
            content: JSON.stringify(this.form),
            osType: this.routeQuery.osType,
            versionCode: this.routeQuery.versionCode,
            userId: this.routeQuery.userId
          })
          .then(function(res) {
            // 已经提交 反馈回APP
            if (res.data.code == 200) {
              that.close("true");
            } else {
              that.close("false");
            }
          });
      } else {
        that.close("false");
      }
    },
    quit() {
      var that = this;
      if (!this.checkForm(101)) {
        this.dialogOnceAgain = true;
      } else {
        axios
          .post("https://app.api.30sec.com.cn/v1/common/CommFeedback/", {
            type: 501,
            content: JSON.stringify(this.form),
            osType: this.routeQuery.osType,
            versionCode: this.routeQuery.versionCode,
            userId: this.routeQuery.userId
          })
          .then(function(res) {
            // 已经提交 反馈回APP
            if (res.data.code == 200) {
              that.close("true");
            } else {
              that.close("false");
            }
          });
      }
    },
    submit() {
      var that = this;
      if (!this.checkForm(101)) {
        this.dialogConfirm = true;
        setTimeout(function() {
          that.dialogConfirm = false;
        }, 2000);
      } else {
        axios
          .post("https://app.api.30sec.com.cn/v1/common/CommFeedback/", {
            type: 501,
            content: JSON.stringify(this.form),
            osType: this.routeQuery.osType,
            versionCode: this.routeQuery.versionCode,
            userId: this.routeQuery.userId
          })
          .then(function(res) {
            // 已经提交 反馈回APP
            console.log(res);
            if (res.data.code == 200) {
              that.dialogSubmitSuccessfully = true;
            } else {
              that.close("false");
            }
          });
      }
    },
    close(a) {
      window.webkit.messageHandlers.AppModel.postMessage({
        funcName: "submitSurvey",
        isOK: a
      });
    }
  },
  created() {
    this.routeQuery = this.$route.query;
  },
  mounted() {}
};
</script>
<style scoped>
.wrapper {
  background-color: #000;
  color: #fff;
  width: 100%;
  min-height: 100vh;
  padding: 30px;
  box-sizing: border-box;
}
.main {
  max-width: 620px;
  margin: 0 auto;
}
.main h1 {
  font-size: 30px;
  font-weight: 900;
}

.form {
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  line-height: 46px;
}
.form h4 {
  font-weight: 900;
  line-height: 33px;
  margin-bottom: 5px;
  width: 100%;
}
.form > div {
  width: 100%;
  margin-top: 30px;
}
.form label {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.form textarea {
  margin-top: 8px;
  font-weight: 500;
  width: 100%;
  resize: none;
  border: 2px #fff solid;
  color: #fff;
  background-color: #000;
  height: auto;
  padding: 5px;
  padding-left: 8px;
  border-radius: 0px;
  box-sizing: border-box;
  height: 130px;
  line-height: 38px;
  font-size: 22px;
}
.form textarea:focus {
  outline: none;
}
.form label::before {
  opacity: 1;
  content: "";
  width: 17px;
  height: 17px;
  margin-right: 16px;
  display: inline-block;
  background: transparent;
  background: url("../../assets/img/icon-checkBox1.svg");
}
input[type="checkbox"]:checked + label:before {
  background: url("../../assets/img/icon-checkBox2.svg");
}
input[type="radio"]:checked + label:before {
  background: url("../../assets/img/icon-checkBox2.svg");
}
.slide-item {
  display: inline-block;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.18s ease;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  margin-bottom: 100px;
  transform: translateY(5px);
}
.submit-box {
  text-align: right; 
} 
.submit {
  color: #fff;
  font-weight: 600;
  display: inline-block;
}
.label-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.label-wrapper .label-box {
  width: 100%;
  white-space: nowrap;
}
.dialog {
  font-size: 20px;
  position: fixed;
  font-weight: 500;
}
.dialog .ties {
  position: absolute;
  bottom: 20px;
  font-size: 16px;
  color: #b4b4b4;
}

.icon-closeXbold {
  position: fixed;
  right: 10px;
  padding: 10px;
  font-size: 30px;
  transition: all 0.25s;
  cursor: pointer;
  color: #fff;
  top: 10px;
}

.icon-closeXbold:hover {
  color: #b4b4b4;
}
.dialog .mes {
  display: flex;
}

@media screen and (max-width: 567px) {
  .label-box {
    width: 100% !important;
  }
}
</style>