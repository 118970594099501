<template>
  <div @touchstart="playStart()" class="wrapper-home">
    <Header />

    <DialogContact :visible="dialogForm" @close="dialogForm = false" />
    <div class="story">
      <!-- <transition name="slide">
        <div
          @click="playFn(0), (dialogAlert = false)"
          v-show="dialogAlert"
          class="dialog mobile-alert"
        >
          <div class="tips">连Wifi 体验更好</div>
          <div class="le-text-btn-underline white">确定</div>
        </div>
      </transition> -->
      <div
        class="ad-box"
        v-for="(guide, index) in guideList"
        :key="'ad' + index"
        :class="{ isShowBox: isShowIndex == index }"
      >
        <div class="slogan" :class="{ isShowTitle: isShowTitle }">
          <div class="title"><span>故事之地</span></div>
          <div class="title2"><span>激励广告平台</span></div>
        </div>
        <div class="cover-mask"></div>
        <svg class="icon icon-next" aria-hidden="true">
          <use xlink:href="#lefticon-back"></use>
        </svg>
        <video
          :class="{ isShowCover: isShowIndex == index }"
          width="100%"
          height="100%"
          class="cover"
          object-fit="fill"
          x5-playsinline=""
          playsinline=""
          webkit-playsinline=""
          poster=""
          x-webkit-airplay="allow"
          preload="auto"
          :id="'video' + index"
          :src="guide.coverUrl"
          :muted="true"
        ></video>
        <div class="remark">
          <div class="mes">{{ guide.remark }}</div>
        </div>
      </div>
    </div>
    <div class="part-1">
      <div class="content">
        <div>在喧嚣嘈杂的移动互联网，</div>
        <div>在哪里讲述品牌故事？</div>
        <div>如何回避干扰？</div>
        <div>如何确保广告被看到？</div>
        <div>如何确保广告被看完？</div>
        <div>如何不被跳过、被静音、被VIP去广告、</div>
        <div>被软件拦截、 被机器人看、被数据造假？</div>
        <div>如何做到广告 <span class="tag">百分百到达</span> ？</div>
        <div>来了解30秒！</div>
        <div>了解我们突破性的解决之道！</div>
        <div>
          了解我们如何做到 <span class="tag">免费投放广告</span>，
          <br class="mobile-br" />
          并实现 <span class="tag">全渠道导流</span> ！
        </div>
        <div>了解我们如何将故事（做品牌）和效果（购买）有机结合！</div>
        <div>了解我们如何帮助你塑造品牌！</div>

        <div class="get-info">
          深入了解，点击获取
          <span
            @click="dialogForm = true"
            @touchmove.prevent
            class="le-text-btn-underline white"
            >《30秒是什么?》</span
          >
          或咨询 business@30sec.com.cn
        </div>
        <br class="mobile-br" />
        <div style="color: #c6c6c6" class="get-info get-info-en">
          For more information in English, please contact business@30sec.com.cn
        </div>
      </div>
    </div>
    <div class="part-2">
      <div class="title">如何在30秒发布广告</div>
      <div class="step">
        <div class="step-box">
          <div class="icon-box">
            <svg class="icon">
              <use xlink:href="#lefticon-why_1"></use>
            </svg>
          </div>

          <div class="tips">1 注册</div>
        </div>
        <div class="step-box">
          <div class="icon-box">
            <svg class="icon">
              <use xlink:href="#lefticon-why_2"></use>
            </svg>
          </div>
          <div class="tips">2 完成企业认证</div>
        </div>
        <div class="step-box">
          <div class="icon-box">
            <svg class="icon">
              <use xlink:href="#lefticon-why_3"></use>
            </svg>
          </div>
          <div class="tips">3 填写广告信息</div>
        </div>
        <div class="step-box">
          <div class="icon-box">
            <svg class="icon">
              <use xlink:href="#lefticon-why_4"></use>
            </svg>
          </div>
          <div class="tips">4 填写优惠信息</div>
        </div>
      </div>
      <a
        href="https://business.30sec.com.cn"
        target="_black"
        class="business-btn"
        >马上入驻</a
      >
    </div>

    <Copyright />
  </div>
</template>

<script>
import Copyright from "@/components/Copyright";
import Header from "@/components/Header";
import DialogContact from "@/components/DialogContact";

export default {
  components: { Header, Copyright, DialogContact },
  props: {},
  data() {
    return {
      guideList: [],
      timerArr: [],
      isPlay: false,
      isShowIndex: 0,
      autoplay: false,
      dialogForm: false,
      ls: "",
      coverTimer: "",
      isShowTitle: false,
    };
  },
  watch: {},
  computed: {
    setIndex() {
      return function (index) {
        if (this.isShowIndex == index) {
          return "index-100";
        } else if (this.isShowIndex - 1 >= 0 && this.isShowIndex - 1 == index) {
          return "index-70";
        } else if (
          this.isShowIndex + 1 <= this.guideList.length - 1 &&
          this.isShowIndex + 1 == index
        ) {
          return "index-70";
        } else if (this.isShowIndex - 2 >= 0 && this.isShowIndex - 2 == index) {
          return "index-40";
        } else if (
          this.isShowIndex + 2 <= this.guideList.length - 1 &&
          this.isShowIndex + 2 == index
        ) {
          return "index-40";
        } else if (this.isShowIndex - 3 >= 0 && this.isShowIndex - 3 == index) {
          return "index-30";
        } else if (
          this.isShowIndex + 3 <= this.guideList.length - 1 &&
          this.isShowIndex + 3 == index
        ) {
          return "index-30";
        } else if (this.isShowIndex - 4 >= 0 && this.isShowIndex - 4 == index) {
          return "index-20";
        } else if (
          this.isShowIndex + 4 <= this.guideList.length - 1 &&
          this.isShowIndex + 4 == index
        ) {
          return "index-20";
        } else if (this.isShowIndex - 5 >= 0 && this.isShowIndex - 5 == index) {
          return "index-10";
        } else if (
          this.isShowIndex + 5 <= this.guideList.length - 1 &&
          this.isShowIndex + 5 == index
        ) {
          return "index-10";
        }
      };
    },
  },
  methods: {
    playStart() {
      if (this.isPlay == false) {
        this.playFn(0);
        this.isPlay = true;
      }
    },
    getGuideList() {
      var that = this;
      this.$api
        .GET("/v1/content/Guide", {
          platform: 5,
          pageSize: 100,
        })
        .then(function (res) {
          console.log(res);
          // 已经提交 反馈回APP
          if (res.code == 200) {
            that.guideList = res.data;
            that.$nextTick(function () {
              setTimeout(function () {
                that.isShowTitle = true;
              }, 200);
            });
            setTimeout(function () {
              that.createdVideo();
            }, 100);
          }
        });
    },

    playFn(index) {
      var that = this;
      var isElevideo = document.getElementById("video" + index);
      this.$nextTick(function () {
        for (let i = 0; i <= that.guideList.length - 1; i++) {
          let elevideo = document.getElementById("video" + i);
          elevideo.pause();
        }
        isElevideo.play();
      });
    },
    createdVideo() {
      this.$nextTick(function () {
        var that = this;
        for (let i = 0; i <= that.guideList.length - 1; i++) {
          let elevideo = document.getElementById("video" + i);
          if (i == 0) {
            that.isShowIndex = 0;
            elevideo.play();
          }

          elevideo.addEventListener(
            "ended",
            function () {
              elevideo.currentTime = 0;
              elevideo.pause();
              let elevideoNext = document.getElementById("video" + (1 + i));

              if (i == that.guideList.length - 1) {
                elevideoNext = document.getElementById("video" + 0);
              }
              elevideoNext.play();
            },
            false
          );

          elevideo.addEventListener(
            "play",
            function () {
              elevideo.currentTime = 0;
              that.isShowIndex = i;
            },
            false
          );
        }
      });
    },
  },
  created() {
    var that = this;
    this.getGuideList();

    (function () {
      var sUserAgent = navigator.userAgent;
      if (
        sUserAgent.indexOf("Android") > -1 ||
        sUserAgent.indexOf("iPhone") > -1 ||
        sUserAgent.indexOf("iPad") > -1 ||
        sUserAgent.indexOf("iPod") > -1 ||
        sUserAgent.indexOf("Symbian") > -1
      ) {
        that.isPlay = false;
      }
    })();
  },
  beforeUnmount() {
    //清除定时器
    clearInterval(this.coverTimer);
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.wrapper-home {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #000;
}
.story {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100vh;
}
.ad-box {
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  .icon-next {
    position: absolute;
    z-index: 999;
    color: #fff;
    transform: rotate(-90deg) translateX(-50%);
    font-size: 40px;
    left: 50%;
    margin-left: -10px;
    bottom: 60px;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-name: breathe;
    -webkit-animation-duration: 800ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
  }
  @-webkit-keyframes breathe {
    0% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }
  .slogan {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
    transform: translate(-50%, -50%) rotate(-9deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
  .slogan .title {
    width: 700px;
    height: 240px;
    font-size: 176px;
    overflow: hidden;
    text-align: center;
    color: #fff;
    font-weight: 900;
  }
  .slogan .title2 {
    width: 440px;
    height: 100px;
    overflow: hidden;
    font-size: 72px;
    color: #fff;
    font-weight: 900;
  }
  .slogan .title span {
    transition: all 0.75s;
  }
  .slogan .title2 span {
    transition: all 1s;
  }
  .slogan span {
    display: block;
    transition: all 0.75s;
    transform: translateY(200px);
  }
  .isShowTitle span {
    transform: translateY(0px) !important;
  }
  .cover {
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
  }

  .cover-mask {
    position: absolute;
    z-index: 98;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
  }
  .isShowCover {
    opacity: 1;
  }

  .ad {
    position: absolute;
    z-index: 99999;
    width: 100%;
    height: 100%;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
  }
  .remark {
    position: absolute;
    z-index: 999;
    right: 80px;
    bottom: 40px;
    .mes {
      font-size: 12px;
      white-space: nowrap;
      color: #fff;
      opacity: 0.4;
    }
  }
}
.isShowBox {
  opacity: 1;
}
.mobile-br {
  display: none;
}
.part-1 {
  width: 100%;

  .content {
    margin: 0 auto;
    font-weight: 400;
    padding: 220px 30px;
    font-size: 40px;
    max-width: 1200px;
    line-height: 79px;
    color: #c6c6c6;
  }
  .content .tag {
    color: #fff;
    font-size: 54px;
    font-weight: bold;
  }
  .get-info {
    margin-top: 120px;
    font-weight: 500;
    font-size: 28px;
    color: #fff;
  }
  .get-info-en {
    margin-top: 0px;
  }
  .get-info span {
    font-size: 28px;
    line-height: 40px;
  }
}

.part-2 {
  width: 100%;
  height: 775px;
  position: relative;
  background-color: #191919;

  .title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 46px;
    padding-top: 100px;
  }
  .step {
    margin: 0 auto;
    max-width: 1000px;
    padding: 90px;
    display: flex;
    justify-content: space-between;
  }

  .step-box {
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    justify-content: flex-end;
    .tips {
      font-size: 29px;
      margin-left: -20px;
    }
  }
  .step-box .icon-box {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .step-box:nth-child(1) .icon {
    font-size: 85px;
  }
  .step-box:nth-child(2) .icon {
    font-size: 90px;
  }
  .step-box:nth-child(3) .icon {
    font-size: 100px;
  }
  .step-box:nth-child(4) .icon {
    font-size: 140px;
  }

  .business-btn {
    position: absolute;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
    padding: 8px 20px;
    font-weight: 900;
    background-color: #fff;
    color: #000;
  }
}

// 手机端

@media screen and (max-width: 1024px) {
  .ad-box {
    .slogan {
      .title {
        font-size: 90px;
        width: 440px;
        height: 120px;
      }
      .title2 {
        font-size: 30px;
        width: 180px;
        height: 70px;
      }
    }
    .icon-next {
      font-size: 30px;
      bottom: 100px;
    }
  }
  .part-1 {
    .content {
      padding: 60px 30px;
      font-size: 24px;
      line-height: 48px;
      .tag {
        font-size: 32px;
      }
    }
    .get-info {
      font-size: 24px;

      span {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
  .part-2 {
    .title {
      font-size: 40px;
    }
    .step {
      .step-box {
        .tips {
          font-size: 18px;
          margin-left: -20px;
        }
      }
      .step-box .icon-box {
        width: 110px;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .step-box:nth-child(1) .icon {
        font-size: 55px;
      }
      .step-box:nth-child(2) .icon {
        font-size: 60px;
      }
      .step-box:nth-child(3) .icon {
        font-size: 90px;
      }
      .step-box:nth-child(4) .icon {
        font-size: 110px;
      }
    }
  }
}

// 手机端
@media screen and (max-width: 579px) {
  .ad-box {
    .slogan {
      .title {
        font-size: 74px;
        width: 350px;
        height: 100px;
      }
      .title2 {
        font-size: 30px;
        width: 180px;
        height: 50px;
      }
    }
    .remark {
      position: absolute;
      width: 100%;
      z-index: 999;
      left: 50%;
      transform: translateX(-50%);
      bottom: 40px;
      .mes {
        width: 100%;
        white-space: nowrap;
        text-align: center;
        font-size: 12px;
        color: #fff;
        opacity: 0.4;
      }
    }
  }
  .part-1 {
    .content {
      padding: 60px 30px;
      font-size: 18px;
      line-height: 32px;
      .tag {
        font-size: 18px;
      }
    }
    .get-info {
      margin-top: 80px;
      font-size: 16px;
      font-weight: 500;
      span {
        font-size: 16px;
        line-height: 23px;
      }
    }
    .get-info-en {
      margin-top: -10px;
    }
    .mobile-br {
      display: block;
    }
  }
  .part-2 {
    height: 576px;
    .title {
      font-size: 20px;
      padding-top: 50px;
    }
    .step {
      flex-wrap: wrap;
      padding: 40px;
      .step-box {
        width: 50%;
        padding: 10px;
        box-sizing: border-box;
        .tips {
          font-size: 15px;
          margin-left: -10px;
        }
      }
      .step-box .icon-box {
        width: 90px;
        height: 90px;
        display: flex;

        align-items: center;
        justify-content: center;
      }
      .step-box:nth-child(1) .icon {
        font-size: 55px;
      }
      .step-box:nth-child(2) .icon {
        font-size: 60px;
      }
      .step-box:nth-child(3) .icon {
        font-size: 70px;
      }
      .step-box:nth-child(4) .icon {
        font-size: 90px;
      }
    }
    .business-btn {
      font-size: 18px;
      bottom: 90px;
      padding: 2px 5px;
    }
  }
  .mobile-alert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .tips {
      width: 70%;
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin-bottom: 50px;
    }
    .le-text-btn-underline {
      font-size: 16px;
    }
  }
}
</style>